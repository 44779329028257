import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 16px;
  width: 100%;
  max-width: 100%;
  @media (max-width: 700px) {
    grid-template-columns: 100%;
  }
`;

import { createGlobalStyle } from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'antd/dist/antd.css';

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  html, body, #root {
    height: 100%;
  }
  body {
    background: #f5f5f5;
    -webkit-font-smoothing: antialiased;
  }
  body, input,button {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
  }
  a {
       text-decoration: none;
  }
  ul {
      list-style: none;
  }
  button {
      cursor: pointer;
  }
  h1 {
    font-weight: 700;
    font-size: 43px;
  }
  h2 {
    font-weight: 700;
    font-size: 38px;
  }
  h3 {
    font-weight: 700;
    font-size: 34px;
  }
  h4 {
    font-weight: 700;
    font-size: 29px;
  }
  h5 {
    font-weight: 700;
    font-size: 25px;
  }
  h6 {
    font-weight: 700;
    font-size: 23px;
  }
  label {
    font-weight: 700;
    font-size: 15px;
  }
  p {
    font-weight: 400;
    font-size: 13px;
  }
  small {
    font-weight: 400;
    font-size: 12px;
  }
`;

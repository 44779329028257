import React, { useMemo, useState } from 'react';

import { useAuth } from 'hooks/auth';
import { useInfluencers } from 'hooks/influencers';
import ActiveCampaign from 'components/Modals/ActiveCampaign';
import { useIntl } from 'react-intl';
import { Spin, Row, Col } from 'antd';
import InfluencerCard from './InfluencerCard';
import { Loader } from './styles';
import { Link } from 'react-router-dom';

const InfluencerContainer: React.FC = () => {
  const { user } = useAuth();
  const intl = useIntl();
  const { consumer_type } = user;
  const { influencers, profilesFound, loading } = useInfluencers();
  const [modal, setModal] = useState(false);

  const guest = useMemo(
    () =>
      consumer_type === 'unassigned_costumer' || consumer_type === undefined,
    [consumer_type],
  );

  if (profilesFound === 0 && !loading) {
    return (
      <h4 style={{ textAlign: 'center' }}>
        {intl.formatMessage({ id: 'home.search-fail' })}
      </h4>
    );
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        {influencers.map((influencer, index) => (
          <Col xs={24} sm={12} md={12} lg={8} key={`card-col-${influencer.id}`}>
            <Link to={`/influencers/${influencer.id}`}>
              <InfluencerCard
                data={influencer}
                index={index + 1}
                key={`influencer-card-${influencer.id}`}
                openModal={setModal}
                enabled={index <= 2 ? true : !guest}
              />
            </Link>
          </Col>
        ))}
      </Row>
      {loading && (
        <Loader>
          <Spin size="large" />
        </Loader>
      )}
      <ActiveCampaign visible={modal} onCancel={() => setModal(false)} />
    </>
  );
};

export default InfluencerContainer;

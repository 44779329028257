import React, { useMemo, useCallback } from 'react';

import { useCurrentInfluencer } from 'hooks/currentInfluencer';
import ReactEcharts from 'echarts-for-react';
import { uniqBy } from 'lodash';
import { Container } from './styles';

interface Props {
  graphType: 'force' | 'circular';
}

const GraphChart: React.FC<Props> = ({ graphType }) => {
  const { currentSocial, currentInfluencer } = useCurrentInfluencer();
  const { top_entities, top_entity_links } = currentInfluencer.social[
    currentSocial
  ];

  const getColorByType = useCallback((type: string): string => {
    if (type === 'MISC') return '#D63928';
    if (type === 'ORG') return '#1E77B4';
    if (type === 'PER') return '#FA7F27';
    if (type === 'LOC') return '#46A02C';
    return 'grey';
  }, []);

  const scaleAmount = useCallback(
    (amount: number): number => {
      const prevMax = top_entities.reduce((a, b) =>
        a.amount > b.amount ? a : b,
      ).amount;
      const prevMin = top_entities.reduce((a, b) =>
        a.amount < b.amount ? a : b,
      ).amount;
      if (prevMax === prevMin) return 10;
      const newMin = 5;
      const newMax = 45;
      const offset = newMin - prevMin;
      const scale = (newMax - newMin) / (prevMax - prevMin);
      return offset + scale * amount;
    },
    [top_entities],
  );

  const data = useMemo(
    () => ({
      nodes: top_entities.map(entity => ({
        symbolSize: scaleAmount(entity.amount),
        category: entity.type,
        id: entity.name,
        name: entity.name,
        draggable: true,
        label: { color: '#000' },
        x: null,
        y: null,
      })),
      links: top_entity_links.map(link => ({
        source: link.from.name,
        target: link.to.name,
      })),
      categories: uniqBy(
        top_entities.map(entity => ({
          name: entity.type,
          itemStyle: { color: getColorByType(entity.type.toUpperCase()) },
        })),
        'name',
      ),
    }),
    [getColorByType, top_entities, top_entity_links, scaleAmount],
  );

  return (
    <Container>
      {data.nodes.length > 0 && data.links.length > 0 && (
        <ReactEcharts
          style={{ height: 500 }}
          option={{
            series: [
              {
                lineStyle: {
                  // color: '#D0CCCC',
                  opacity: 0.5,
                  color: 'source',
                  curveness: graphType === 'circular' ? 0.3 : 0,
                },
                type: 'graph',
                roam: true,
                layout: graphType,
                circular: {
                  rotateLabel: true,
                },
                data: data.nodes,
                links: data.links,
                categories: data.categories,
                focusNodeAdjacency: true,
                label: {
                  show: true,
                  position: 'right',
                },
                emphasis: {
                  itemStyle: {
                    borderColor: '#000',
                  },
                  label: {
                    fontWeight: 'bold',
                  },
                  lineStyle: {
                    width: 1,
                    opacity: 1,
                  },
                },
                force: {
                  gravity: 0.07,
                  repulsion: 150,
                  edgeLength: 70,
                },
              },
            ],
          }}
        />
      )}
    </Container>
  );
};

export default GraphChart;

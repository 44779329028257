import { Form, Input } from 'antd';
import styled from 'styled-components';

export const Container = styled(Form)`
  display: block;
  width: 100%;
  padding: 0;
  input {
    border-radius: 15px;
    overflow: hidden;
  }

  div.ant-checkbox-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    label {
      margin-left: 16px;
    }
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const InputSearch = styled(Input.Search)`
  .ant-input-search {
    border-radius: 15px 0px 0px 15px;
  }

  > .ant-input {
    border-radius: 0px;
  }

  button {
    border-radius: 15px;
  }
`;

interface FilterProps {
  open: boolean;
}

import React from 'react';

import { Avatar, List, Checkbox } from 'antd';

import formatNumber from 'utils/formatNumber';
import fixNumberDigits from 'utils/fixNumberDigits';

import { InfluencerType } from 'types';
import SocialIcon from 'components/SocialIcon';
import InfluencerFavoriteButton from 'components/InfluencerFavoriteButton';
import { uuid } from 'uuidv4';
import { SocialFollowers, FavoriteButtonContainer } from './styles';

interface InfluencersListProps {
  list: InfluencerType[];
  checkable?: boolean;
  checkboxValue?: (influencer: InfluencerType) => void;
  favoritable?: boolean;
  photoSize?: 'small' | 'default' | 'large';
}

const InfluencersList: React.FC<InfluencersListProps> = ({
  list,
  checkable = false,
  checkboxValue = item => item,
  favoritable = false,
  photoSize = 'default',
}) => {
  return (
    <List
      dataSource={list}
      renderItem={item => (
        <List.Item
          style={{ alignItems: 'baseline', position: 'relative' }}
          key={item.id}
          actions={[]}
        >
          {checkable && (
            <Checkbox
              style={{ marginRight: '15px' }}
              value={checkboxValue(item)}
              key={`checkbox${item.id}`}
            />
          )}
          <List.Item.Meta
            avatar={<Avatar src={item.photo} size={photoSize} />}
            title={
              <a
                href={`${process.env.REACT_APP_BASE_URL}influencers/${item.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.name}
              </a>
            }
            description={item.social.map(social => (
              <SocialFollowers key={uuid()}>
                <SocialIcon social_network={social.social_network} />
                {formatNumber(fixNumberDigits(social.followers) || 0)}
              </SocialFollowers>
            ))}
          />
          {favoritable ? (
            <FavoriteButtonContainer>
              <InfluencerFavoriteButton influencerId={item.id || ''} />
            </FavoriteButtonContainer>
          ) : null}
        </List.Item>
      )}
    />
  );
};

export default InfluencersList;

import React, { useState, useCallback } from 'react';
import { CheckboxProps } from 'antd/lib/checkbox';
import { Checkbox } from 'antd';

interface SocialCheckboxProps extends CheckboxProps {
  disabledIcon: React.ReactNode;
  activeIcon: React.ReactNode;
}

const SocialCheckbox: React.FC<SocialCheckboxProps> = ({
  value,
  disabledIcon,
  activeIcon,
  checked,
}) => {
  const [icon, setIcon] = useState<React.ReactNode>(activeIcon);

  const onChange = useCallback(
    isChecked => {
      console.log(isChecked);

      if (isChecked.target.checked) {
        setIcon(activeIcon);
      } else {
        setIcon(disabledIcon);
      }
    },
    [activeIcon, disabledIcon],
  );

  return (
    <Checkbox value={value} onChange={onChange} checked={checked}>
      {icon}
    </Checkbox>
  );
};

export default SocialCheckbox;

import { InputNumber, Form, Modal, Select, Checkbox } from 'antd';
import styled from 'styled-components';

interface FilterProps {
  open: boolean;
}

export const FilterLargeScreen = styled.div<FilterProps>`
  opacity: ${props => (props.open ? '1' : '0')};
  height: ${props => (props.open ? 'auto' : '0px')};
  padding: ${props => (props.open ? '20px 18px 10px' : '0px')};
  overflow: hidden;
  justify-content: space-between;
  transition: all 0.1s ease-out;
`;

export const FilterSmallScreen = styled(Modal).attrs((props: FilterProps) => ({
  visible: props.open,
}))<FilterProps>`
  .ant-modal-body {
    padding: 32px;
  }
`;

export const FormInputNumber = styled(InputNumber).attrs(props => ({
  value: props.value,
  onChange: props.onChange,
  formatter: (value: string) =>
    value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.'),
  parser: (value: string) => parseInt(value.replace(/\./g, ''), 10) || 1000,
  min: 1000,
  max: 1000000000,
}))`
  min-width: 115px;
  @media (max-width: 1080px) {
    width: 100%;
  }
`;

export const FollowersRange = styled(Form.Item)`
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  margin: 0;
  .ant-form-item-label {
    padding: 0px 4px 0px 0px;
  }
  @media (max-width: 1080px) {
    width: 100%;
  }
`;

export const SocialsCheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  .ant-checkbox-wrapper > .ant-checkbox {
    display: none;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin: 0;
  }
  .ant-checkbox-wrapper span:last-of-type {
    display: inline-block;
    line-height: 0;
    vertical-align: middle;
  }
  .ant-checkbox-wrapper span img {
    max-height: 20px;
  }
`;

export const SelectLanguage = styled(Select).attrs({
  mode: 'multiple',
})`
  .ant-select-selector {
    height: 32px;
    overflow: auto;
  }
`;

export const SecondFilter = styled.div`
  display: grid;
  column-gap: 12px;
  grid-template-areas: 'social profiletype';
  grid-template-columns: repeat(10, auto);
  grid-template-rows: min-content;
  margin-top: 20px;

  @media (max-width: 1080px) {
    grid-template-areas: 'social profiletype';
    grid-template-rows: repeat(5, min-content);
    grid-template-columns: 1fr 1fr;
    margin-top: 0;
  }

  @media (max-width: 490px) {
    grid-template-areas:
      'social social'
      'profiletype profiletype';

    grid-template-rows: repeat(5, min-content);
    grid-template-columns: 1fr 1fr;
  }
`;

export const ThirdFilter = styled.div`
  display: grid;
  column-gap: 12px;
  grid-template-areas: 'followers age languages countries';
  grid-template-columns: repeat(3fr, auto);
  grid-template-rows: min-content;
  margin-top: 20px;

  @media (max-width: 1080px) {
    grid-template-areas:
      'followers followers'
      'age age'
      'languages languages'
      'countries countries';
    grid-template-rows: repeat(5, min-content);
    grid-template-columns: 1fr 1fr;
    margin-top: 0;
  }

  @media (max-width: 490px) {
    grid-template-areas:
      'followers followers'
      'age age'
      'languages languages'
      'countries countries';

    grid-template-rows: repeat(5, min-content);
    grid-template-columns: 1fr 1fr;
  }
`;

export const Row = styled.div`
  display: flex;
  > div + div {
    margin-left: 10px;
  }
`;

export const Field = styled(Form.Item).attrs({})`
  margin-bottom: 0px;
  @media (max-width: 1080px) {
    margin-bottom: 25px;
  }
`;

export const CheckboxGroup = styled(Checkbox.Group).attrs({})`
  display: flex;
  @media (max-width: 1080px) {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, min-content));
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin: 0;
    }
  }
`;

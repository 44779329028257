/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';

import { Tabs, Skeleton } from 'antd';

import { useCurrentInfluencer } from 'hooks/currentInfluencer';

import SocialIcon from 'components/SocialIcon';
import EvolutionCharts from './EvolutionCharts';
import LastPosts from './LastPosts';
import Metrics from './Metrics';
import ProfileBio from './ProfileBio';
import RankingCharts from './RankingCharts';
import { Container, ContainerLoading, Icon } from './styles';

const { TabPane } = Tabs;

const ProfileSocial: React.FC = () => {
  const {
    currentInfluencer,
    loading,
    setCurrentSocial,
  } = useCurrentInfluencer();
  const { social } = currentInfluencer;

  const handleChangeTab = useCallback(
    (index: string): void => setCurrentSocial(Number(index)),
    [setCurrentSocial],
  );

  if (loading) {
    return (
      <Container>
        <ContainerLoading>
          <Skeleton active loading={loading} avatar={{ size: 120 }} />
          <Skeleton active loading={loading} />
          <Skeleton active loading={loading} />
          <Skeleton active loading={loading} />
        </ContainerLoading>
      </Container>
    );
  }

  return (
    <Container>
      <Tabs type="card" onChange={handleChangeTab}>
        {social.map((network, index) => (
          <TabPane
            tab={
              <Icon>
                <SocialIcon social_network={network.social_network} />
              </Icon>
            }
            key={index}
          >
            <ProfileBio />
            <Metrics />
            <EvolutionCharts />
            <RankingCharts />
            <LastPosts />
          </TabPane>
        ))}
      </Tabs>
    </Container>
  );
};

export default ProfileSocial;

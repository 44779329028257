import styled from 'styled-components';

import { roundedCorners, defaultBorder } from 'styles/reuse';

export const Container = styled.div`
  .ant-tabs-card .ant-tabs-content {
    ${defaultBorder};
    height: auto;
    margin-top: -17px;
    border-radius: 0px 4px 4px 4px;
  }

  .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 24px;
    border-radius: 0px 4px 4px 4px;
    display: grid;
    grid-gap: 16px;
    @media (max-width: 1080px) {
      padding: 16px;
    }
  }

  .ant-tabs-card > .ant-tabs-nav {
    border: 0px;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
    margin-right: 2px;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    ${defaultBorder};
    border-radius: 4px 4px 0px 0px;
    border-bottom: 0px;
    background: transparent;
    padding: 8px 10px;
    height: auto;
    line-height: 0px;
    color: ${props => props.theme.colors.tertiary};
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab:hover {
    color: ${props => props.theme.colors.primaryLight};
  }

  .ant-tabs-tab .anticon {
    margin-right: 0px;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    background: #fff;
  }
`;

export const ContainerLoading = styled.div`
  ${roundedCorners}
  ${defaultBorder};
  background: #fff;
  padding: 24px;
  display: flex;
  flex-direction: column;
  div + div {
    margin-top: 12px;
  }
`;

export const Icon = styled.div`
  font-size: 26px;
`;

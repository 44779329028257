import React, { useCallback, useState } from 'react';
import { message, Modal } from 'antd';
import { useIntl } from 'react-intl';
import { useCampaigns } from 'hooks/campaigns';

interface DeleteModalProps {
  visible: boolean;
  campaignId: string;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  visible,
  onCancel,
  campaignId,
}) => {
  const intl = useIntl();
  const { deleteCampaign } = useCampaigns();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleSubmit = useCallback(() => {
    setLoadingSubmit(true);
    deleteCampaign(campaignId)
      .then(() => {
        message.success(
          intl.formatMessage({
            id: 'campaigns.modal.delete.success',
          }),
        );
      })
      .catch(() => {
        message.error(
          intl.formatMessage({
            id: 'campaigns.modal.delete.error',
          }),
        );
      });
  }, [campaignId, deleteCampaign, intl]);

  return (
    <Modal
      destroyOnClose
      cancelText={intl.formatMessage({
        id: 'campaigns.modal.cancel',
      })}
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={loadingSubmit}
    >
      <p>
        {intl.formatMessage({
          id: 'campaigns.modal.delete.warning',
        })}
      </p>
      <p>
        {intl.formatMessage({
          id: 'campaigns.modal.delete.warning-continue',
        })}
      </p>
    </Modal>
  );
};

export default DeleteModal;

/* eslint-disable no-underscore-dangle */
import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Form, Input, message, Modal } from 'antd';
import { useCampaigns } from 'hooks/campaigns';
import SearchFavorites from 'components/SearchFavorites';
import { CampaignType } from 'types';

interface EditModalProps {
  visible: boolean;
  campaign: CampaignType;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onOk: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const EditModal: React.FC<EditModalProps> = ({
  visible,
  onCancel,
  onOk,
  campaign,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { updateCampaign } = useCampaigns();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [selectedInfluencers, setSelectedInfluencers] = useState(
    campaign.campaign_favorites.map(item => item.id),
  );

  const onSubmitSuccess = useCallback(
    (e): void => {
      message.success(
        intl.formatMessage({
          id: 'campaigns.modal.edit.success',
        }),
        2,
      );
      if (onOk) onOk(e);
    },
    [intl, onOk],
  );

  const onSubmitFail = useCallback(() => {
    message.error(
      intl.formatMessage({
        id: 'campaigns.modal.edit.error',
      }),
      2,
    );
  }, [intl]);

  const handleSubmit = useCallback(
    e => {
      setLoadingSubmit(true);
      form
        .validateFields()
        .then(values => {
          updateCampaign(campaign._id, {
            name: values.name,
            description: values.description,
            influencers_favorites_ids: selectedInfluencers,
          })
            .then(() => {
              onSubmitSuccess(e);
            })
            .catch(() => {
              onSubmitFail();
            })
            .finally(() => setLoadingSubmit(false));
        })
        .catch(() => {
          setLoadingSubmit(false);
        });
    },
    [
      campaign._id,
      form,
      onSubmitFail,
      onSubmitSuccess,
      selectedInfluencers,
      updateCampaign,
    ],
  );

  const handleCancel = useCallback(
    e => {
      if (onCancel) onCancel(e);
      form.setFieldsValue(campaign);
      setLoadingSubmit(false);
    },
    [campaign, form, onCancel],
  );

  return (
    <Modal
      destroyOnClose
      cancelText={intl.formatMessage({
        id: 'campaigns.modal.cancel',
      })}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleSubmit}
      title={intl.formatMessage({
        id: 'campaigns.modal.edit.title',
      })}
      confirmLoading={loadingSubmit}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...campaign,
        }}
      >
        <Form.Item
          label={intl.formatMessage({
            id: 'campaigns.modal.name',
          })}
          name="name"
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'campaigns.modal.name.error',
              }),
            },
          ]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'campaigns.modal.name.placeholder',
            })}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'campaigns.description',
          })}
          name="description"
        >
          <Input.TextArea
            placeholder={intl.formatMessage({
              id: 'campaigns.modal.description.placeholder',
            })}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'campaigns.influencers',
          })}
          name="favorites"
        >
          <SearchFavorites
            checkable
            onCheckboxChange={setSelectedInfluencers}
            checkedFavorites={campaign.campaign_favorites.map(item => item.id)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditModal;

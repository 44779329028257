import React, { createContext, useState, useContext, useCallback } from 'react';

import api from 'services/crawlerApi';
import { InfluencerType, SocialType } from 'types/index';

interface ContextProps {
  currentInfluencer: InfluencerType;
  loading: boolean;
  currentSocial: number;
  setCurrentInfluencer: (influencer: InfluencerType) => void;
  loadInfluencerData: (id: string) => void;
  updateInfluencer: (
    id: string,
    influencer: InfluencerType,
  ) => Promise<unknown>;
  setCurrentSocial: (currentSocial: number) => void;
}

const CurrentInfluencerContext = createContext<ContextProps>(
  {} as ContextProps,
);

const CurrentInfluencerProvider: React.FC = ({ children }) => {
  const [currentInfluencer, setCurrentInfluencer] = useState<InfluencerType>({
    social: [] as SocialType[],
  } as InfluencerType);
  const [currentSocial, setCurrentSocial] = useState(0);
  const [loading, setLoading] = useState(true);

  const loadInfluencerData = useCallback(
    id => {
      setLoading(true);
      api
        .get(`/influencers/profile/${id}`)
        .then(res => {
          setCurrentInfluencer({ ...res.data, id });
          setCurrentSocial(0);
        })
        .finally(() => setLoading(false));
    },
    [setCurrentInfluencer],
  );

  const updateInfluencer = useCallback(
    (id: string, profile): Promise<void> => {
      const { ...updatedProfile } = profile as InfluencerType;

      return new Promise<void>((resolve, reject) => {
        api
          .put(`/influencers/profile/${id}`, updatedProfile)
          .then(() => {
            setCurrentInfluencer({ ...currentInfluencer, ...updatedProfile });
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    [currentInfluencer],
  );

  return (
    <CurrentInfluencerContext.Provider
      value={{
        currentInfluencer,
        loading,
        currentSocial,
        setCurrentInfluencer,
        loadInfluencerData,
        updateInfluencer,
        setCurrentSocial,
      }}
    >
      {children}
    </CurrentInfluencerContext.Provider>
  );
};

export function useCurrentInfluencer(): ContextProps {
  const context = useContext(CurrentInfluencerContext);
  if (!context) {
    throw new Error(
      'useInfluencers must be used within an CurrentInfluencerProvider!',
    );
  }
  return context;
}

export default CurrentInfluencerProvider;

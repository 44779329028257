import { Space } from 'antd';
import styled from 'styled-components';

export const SocialFollowers = styled(Space)`
  margin-right: 10px;
`;

export const FavoriteButtonContainer = styled.div`
  position: absolute;
  top: -1px;
  right: 0;
  width: fit-content;
  height: fit-content;
`;

import styled from 'styled-components';
import { Typography } from 'antd';

const { Title } = Typography;

export const ModalTitle = styled(Title)`
  display: flex;
  align-items: center;
  .anticon {
    font-size: 25px;
    margin-right: 10px;
    color: ${props => props.theme.colors.primary};
  }
`;

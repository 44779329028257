import React from 'react';
import {
  FacebookFilled,
  InstagramFilled,
  YoutubeFilled,
} from '@ant-design/icons';

import IconXDisabled from 'assets/icons/socials/icon-x-disable.svg';
import IconYoutube from 'assets/icons/socials/icon-youtube.png';

import { StyledImage } from './style';

interface SocialIconProps {
  social_network: string;
}

const SocialIcon: React.FC<SocialIconProps> = ({ social_network }) => {
  if (social_network === 'facebook') return <FacebookFilled />;
  if (social_network === 'twitter') return <StyledImage src={IconXDisabled} />;
  if (social_network === 'youtube') return <StyledImage src={IconYoutube} />;
  return <InstagramFilled />;
};

export default SocialIcon;

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip, Button } from 'antd';
import { useAuth } from 'hooks/auth';
import {
  LeftOutlined,
  CloudDownloadOutlined,
  HomeOutlined,
  HeartOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import ActiveCampaign from 'components/Modals/ActiveCampaign';
import DownloadModal from 'components/Modals/Download';
import { useIntl } from 'react-intl';
import { FilterOutlined } from '@ant-design/icons';
import { FiltersType, useInfluencers } from 'hooks/influencers';
import Search from './Search';
import { Container, Bar, MenuButton, ButtonsContainer } from './styles';
import Filter from './Filter';
import AddInfluencerModal from './AddInfluencerModal';

const Menu: React.FC = () => {
  const history = useHistory();
  const intl = useIntl();
  const { influencers } = useInfluencers();
  const { user } = useAuth();
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showAddInfluencerModal, setShowAddInfluencerModal] = useState(false);
  const { newSearch, filters } = useInfluencers();
  const [historyPath, setHistoryPath] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [searchFilters, setSearchFilters] = useState<FiltersType>(filters);

  useEffect(() => {
    newSearch(filters);
    const path = history.location.pathname;
    if (path === '/') {
      setShowFilter(true);
    }
    setHistoryPath(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFiltersChange = useCallback(newFilters => {
    setSearchFilters(prevFilters => ({ ...prevFilters, ...newFilters }));
  }, []);

  const onSearch = useCallback(
    newFilters => {
      newSearch({ ...searchFilters, ...newFilters });
    },
    [searchFilters, newSearch],
  );

  // Listen to changes on history and updates historyPath state
  useEffect(() => {
    history.listen(location => {
      setHistoryPath(location.pathname);
      if (location.pathname !== '/') {
        setShowFilter(false);
      }
    });
  });

  const isGuest = useMemo(
    () => !user?.consumer_type || user?.consumer_type === 'unassigned_costumer',
    [user],
  );

  const BackButton = useCallback(
    () =>
      historyPath !== '/' ? (
        <CustomTooltip title={intl.formatMessage({ id: 'menu.tooltip.back' })}>
          <IconButton
            icon={<LeftOutlined />}
            onClick={() => history.goBack()}
          />
        </CustomTooltip>
      ) : null,
    [history, historyPath, intl],
  );

  const ModalOpener = useCallback(
    ({ modalName, ...rest }) => {
      if (!isGuest) {
        if (modalName === 'download')
          return <DownloadModal influencers={influencers} {...rest} />;
        if (modalName === 'addInfluencer')
          return <AddInfluencerModal {...rest} />;
      }
      return <ActiveCampaign {...rest} />;
    },
    [isGuest, influencers],
  );

  const FavoritesPageButton = useCallback(
    () => (
      <IconButton
        icon={<HeartOutlined />}
        onClick={
          isGuest
            ? () => setShowDownloadModal(true)
            : () => history.push('/favorites')
        }
      />
    ),
    [history, isGuest],
  );

  function handleHome(): void {
    history.push('/');
    window.location.reload();
  }

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 57) {
      setShowFilter(false);
    } else if (
      document.getElementsByTagName('body')[0].clientWidth >= 1080 &&
      historyPath === '/' && window.pageYOffset != 0
    ) {
      setShowFilter(true);
    }
  }, [historyPath]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return function cleanupListener() {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <Container>
        <Bar>
          <ButtonsContainer>
            <BackButton />
            <CustomTooltip
              title={intl.formatMessage({ id: 'menu.tooltip.home' })}
            >
              <IconButton icon={<HomeOutlined />} onClick={handleHome} />
            </CustomTooltip>
            <Search filters={filters} onSearch={onSearch} />
            <Button
              shape="round"
              icon={<FilterOutlined />}
              onClick={() => setShowFilter(!showFilter)}
            >
              {intl.formatMessage({ id: 'menu.button.filters' })}
            </Button>
            <CustomTooltip
              title={intl.formatMessage({ id: 'menu.tooltip.favorites' })}
            >
              <FavoritesPageButton />
            </CustomTooltip>
            <CustomTooltip
              title={intl.formatMessage({ id: 'menu.tooltip.download' })}
            >
              <IconButton
                icon={<CloudDownloadOutlined />}
                onClick={() => setShowDownloadModal(true)}
              />
            </CustomTooltip>
            {
            <CustomTooltip
              title={intl.formatMessage({ id: 'menu.tooltip.add-influencer' })}
            >
              <IconButton
                icon={<UsergroupAddOutlined />}
                onClick={() => setShowAddInfluencerModal(true)}
              />
            </CustomTooltip>
            }
          </ButtonsContainer>
          <Filter
            isVisible={showFilter}
            onOk={() => setShowFilter(false)}
            initialFilters={filters}
            handleFiltersChange={handleFiltersChange}
          />
        </Bar>
        <ModalOpener
          visible={showDownloadModal}
          onCancel={() => setShowDownloadModal(false)}
          modalName="download"
        />
        <ModalOpener
          visible={showAddInfluencerModal}
          onCancel={() => setShowAddInfluencerModal(false)}
          modalName="addInfluencer"
        />
      </Container>
    </>
  );
};

interface IconButtonProps {
  icon: React.ReactNode;
  onClick: () => void;
}

const IconButton: React.FC<IconButtonProps> = ({ icon, onClick }) => {
  return <MenuButton onClick={onClick}>{icon}</MenuButton>;
};

interface CustomTooltipProps {
  title: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ title, children }) => {
  return (
    <Tooltip placement="bottom" title={title} destroyTooltipOnHide>
      <span style={{ height: 'fit-content' }}>{children}</span>
    </Tooltip>
  );
};

export default Menu;

import styled from 'styled-components';
import { Input } from 'antd';
import { scrollBar } from 'styles/reuse';

export const InputSearch = styled(Input.Search)`
  margin: 0;
  input {
    overflow: hidden;
  }
`;

export const ListContainer = styled.div`
  ${scrollBar};
  max-height: 400px;
  margin-top: 15px;
  padding: 8px 15px 8px 10px;
  overflow: auto;
`;

import { Card, Divider, Avatar } from 'antd';
import styled from 'styled-components';

import { roundedCorners, defaultBorder } from 'styles/reuse';

export const Container = styled(Card).attrs({})`
  ${roundedCorners};
  ${defaultBorder};
  background-color: white;
  height: fit-content;
  width: 100%;
  max-width: 100%;
  h5 {
    max-width: 251px;
  }
  div.ant-skeleton-header {
    padding: 0;
  }
  @media (min-width: 600px) and (max-width: 1080px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
  .ant-card-body {
    position: relative;

    @media (max-width: 1080px) {
      padding: 16px;
    }
  }
  .disabled {
    color: #ccc !important;
    pointer-events: none;
  }
`;

export const CardCover = styled(Avatar).attrs({})`
  border-radius: 4px 4px 0px 0px;
  height: 300px;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  span {
    height: auto;
    width: 100%;
  }
  svg {
    height: 150px;
    width: 150px;
  }
  @media (min-width: 600px) and (max-width: 1080px) {
    border-radius: 4px 0px 0px 4px;
    img {
      border-radius: 4px 0px 0px 4px;
    }
  }
`;

export const Label = styled.label``;

export const SectionDivider = styled(Divider)`
  margin: 2px 0px 0px;
`;

export const TagContainer = styled.div`
  padding: 8px 0px;
`;

export const TagGroup = styled.div`
  padding: 16px 4px 4px 4px;

  .ant-tag {
    margin-bottom: 8px;
  }
`;

export const FavoriteButtonContainer = styled.div`
  position: absolute;
  top: -1px;
  right: 12px;
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 16px;
`;

export const MetricContainer = styled.p`
  font-size: 20px;
  text-align: center;
`;

import { Avatar, Card, Divider } from 'antd';
import styled, { css } from 'styled-components';

import { roundedCorners, defaultBorder } from 'styles/reuse';

export const Container = styled(Card)<{ enabled: boolean }>`
  ${roundedCorners};
  ${defaultBorder};
  height: 280px;
  width: 100%;
  padding: 15px;
  ${props =>
    !props.enabled &&
    css`
      filter: blur(3.5px);
      user-select: none;
    `}
  :hover {
    cursor: pointer;
    border-color: ${props => props.theme.colors.primaryLight};
  }
  .ant-card-head {
    min-height: 38px;
    height: 38px;
  }
  .ant-card-body {
    padding: 24px 0 8px;
  }
  .ant-card-head-title {
    overflow: visible;
    display: block;
    padding: 0;
  }
`;

export const CardHeader = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: auto;
  span {
    width: 40px;
  }
`;

export const CardContent = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 100%);
  grid-gap: 8px;
  margin-top: 32px;
`;

export const CardIndex = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid ${props => props.theme.colors.tertiaryDark};
  border-radius: 50%;
  font-size: 13px;
  color: ${props => props.theme.colors.tertiary};
  z-index: 2;
`;

export const CardPicture = styled(Avatar).attrs(props => ({
  icon: props.icon,
  size: 75,
}))`
  background: #fff;
  border: 2px solid ${props => props.theme.colors.tertiary};
  overflow: visible;
  justify-self: center;
  svg {
    color: ${props => props.theme.colors.tertiary};
  }
  img {
    border-radius: 50%;
  }
`;

export const Name = styled.span`
  color: ${props => props.theme.colors.tertiary};
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  max-width: 220px;
  overflow: hidden;
  text-align: center;
  height: 44px;
  overflow: hidden;
  margin: 0 auto;
`;

export const Social = styled.div`
  display: flex;
  align-items: baseline;
  padding: 0;
  margin: 0 auto;
  strong {
    color: ${props => props.theme.colors.primaryLight};
  }
`;

export const SocialFollowers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${props => props.theme.colors.tertiary};
  padding: 0 8px;
  span {
    font-weight: bold;
    font-size: 16px;
  }
  svg {
    height: 24px;
    width: auto;
    margin-bottom: 4px;
  }
`;

export const TagDivider = styled(Divider).attrs({
  orientation: 'left',
})`
  margin: 0px !important;
  color: ${props => props.theme.colors.tertiary} !important;
  font-size: 13px !important;
`;

export const TagList = styled.div`
  div {
    width: 100%;
    margin-top: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    span {
      display: inline-block;
    }
    strong {
      color: ${props => props.theme.colors.primaryLight};
    }
  }
`;

export const FavoriteButtonContainer = styled.div`
  position: absolute;
  top: -1px;
  right: 15px;
  width: fit-content;
  height: fit-content;
`;

import i18Countries from 'i18n-iso-countries';
import countries from 'countries/countries';

import React, { useMemo, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';

import {
  UserOutlined,
  EditOutlined,
  MergeCellsOutlined,
  SplitCellsOutlined,
  ReloadOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Tag, Typography, Skeleton, Tooltip } from 'antd';

import { uniq } from 'lodash';

import { useAuth } from 'hooks/auth';
import useCldrTranslator from 'hooks/cldrTranslator';
import { useCurrentInfluencer } from 'hooks/currentInfluencer';

import InfluencerFavoriteButton from 'components/InfluencerFavoriteButton';
import EditModal from './EditModal';
import MergeModal from './MergeModal';
import SplitModal from './SplitModal';
import ForceUpdateModal from './ForceUpdateModal';

import {
  Container,
  TagContainer,
  TagGroup,
  CardCover,
  SectionDivider,
  Label,
  FavoriteButtonContainer,
} from './styles';

import ReportModal from './ReportModal';

const { Paragraph, Title } = Typography;

// eslint-disable-next-line @typescript-eslint/no-var-requires
i18Countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
i18Countries.registerLocale(require('i18n-iso-countries/langs/pt.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
i18Countries.registerLocale(require('i18n-iso-countries/langs/es.json'));

const ProfileHeader: React.FC = () => {
  const intl = useIntl();
  const { user } = useAuth();
  const translateLanguage = useCldrTranslator(
    intl.formatMessage({ id: 'general.locale' }),
  );
  const { currentInfluencer, loading } = useCurrentInfluencer();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openSplitModal, setOpenSplitModal] = useState(false);
  const [openMergeModal, setOpenMergeModal] = useState(false);
  const [openForceUpdateModal, setOpenForceUpdateModal] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);

  const employee = useMemo(
    () => user.consumer_type && user?.consumer_type === 'employee',
    [user],
  );

  const isGuest = useMemo(
    () => !user?.consumer_type || user?.consumer_type === 'unassigned_costumer',
    [user],
  );

  const {
    id,
    name,
    photo,
    languages,
    interests,
    countries: influencers_countries, 
    wikipedia_description,
    social,
  } = currentInfluencer;

  const formattedLanguages = useMemo(() => {
    let result = languages && [...languages];
    if ((!languages || languages.length === 0) && social && social.length > 0) {
      result = uniq(
        social[0].last_posts_content.slice(0, 5).map(post => {
          return post.language;
        }),
      );
    }

    return result?.flatMap((lang: string) => {
      return translateLanguage(lang) || [];
    });
  }, [languages, social, translateLanguage]);

  const profileActions = useMemo(
    () => [
      <Tooltip 
        title={intl.formatMessage({ id: 'profile.header.modal.edit.title' })} 
        placement="bottom">
        <EditOutlined key="edit" onClick={() => setOpenEditModal(true)} />
      </Tooltip>,
      <Tooltip 
        title={intl.formatMessage({ id: 'profile.header.merge' })} 
        placement="bottom">
        <MergeCellsOutlined
          key="merge"
          onClick={() => setOpenMergeModal(true)}
        />
      </Tooltip>,
      <Tooltip 
        title={intl.formatMessage({ id: 'profile.header.split' })} 
        placement="bottom">
        <SplitCellsOutlined
          className={currentInfluencer.social.length <= 1 ? 'disabled' : ''}
          key="split"
          onClick={() => setOpenSplitModal(true)}
        />
      </Tooltip>,
      <Tooltip 
        title={intl.formatMessage({ id: 'profile.header.force_update' })} 
        placement="bottom">
        <ReloadOutlined
          key="reload"
          onClick={() => setOpenForceUpdateModal(true)}
        />
      </Tooltip>,
      <Tooltip
        title={intl.formatMessage({ id: 'profile.header.modal.report.title' })}
        placement="bottom"
      >
        <WarningOutlined
          key="report"
          onClick={() => setOpenReportModal(true)}
        />
      </Tooltip>,
    ],
    [currentInfluencer.social.length, intl],
  );

  const FavoriteButton = useCallback(
    () => (
      <FavoriteButtonContainer>
        <InfluencerFavoriteButton influencerId={`${id}`} />
      </FavoriteButtonContainer>
    ),
    [id],
  );

  const profileHeaderActions = useMemo(() => {
    if (employee) {
      return profileActions;
    }
    if (!isGuest) {
      return [profileActions[3], profileActions[4]];
    }
    return [];
  }, [employee, isGuest, profileActions]);

  return (
    <Container
      cover={
        <Skeleton
          active
          loading={loading}
          title={false}
          paragraph={false}
          avatar={{ shape: 'square', size: 300 }}
        >
          {photo && <CardCover src={photo} icon={<UserOutlined />} />}
        </Skeleton>
      }
      actions={profileHeaderActions}
    >
      <Skeleton active loading={loading}>
        <FavoriteButton />
        <Title level={4} ellipsis={{ rows: 2, expandable: false }}>
          {name}
        </Title>
      </Skeleton>
      <Paragraph ellipsis={{ rows: 4, expandable: false }}>
        {wikipedia_description}
      </Paragraph>
      {influencers_countries !== undefined && influencers_countries.length > 0 && (
        <TagSection
          label={intl.formatMessage({ id: 'profile.header.countries' })}
          tags={influencers_countries.map(country => {
            const language = user.language || 'en';
            let country_flag = ''
            if (country && countries[country] ) {
              country_flag = countries[country].emoji
            }
            return `${i18Countries.getName(country, language)} ${country_flag}`
          })}
        />
      )}
      {formattedLanguages !== undefined && formattedLanguages.length > 0 && (
        <TagSection
          label={intl.formatMessage({ id: 'profile.header.languages' })}
          tags={formattedLanguages}
        />
      )}
      {interests && interests.length > 0 && (
        <TagSection
          label={intl.formatMessage({ id: 'profile.header.interests' })}
          tags={interests}
        />
      )}
      <EditModal
        visible={openEditModal}
        onCancel={() => setOpenEditModal(false)}
        onOk={() => setOpenEditModal(false)}
      />
      <MergeModal
        visible={openMergeModal}
        onCancel={() => setOpenMergeModal(false)}
        onOk={() => setOpenMergeModal(false)}
      />
      <SplitModal
        visible={openSplitModal}
        onCancel={() => setOpenSplitModal(false)}
        onOk={() => setOpenSplitModal(false)}
      />
      <ForceUpdateModal
        visible={openForceUpdateModal}
        onCancel={() => setOpenForceUpdateModal(false)}
        onOk={() => setOpenForceUpdateModal(false)}
      />
      <ReportModal
        visible={openReportModal}
        onCancel={() => setOpenReportModal(false)}
        onOk={() => setOpenReportModal(false)}
      />
    </Container>
  );
};

interface TagSectionProps {
  label: string;
  tags: string[];
}

const TagSection: React.FC<TagSectionProps> = ({ label, tags }) => {
  return (
    <TagContainer>
      <Label>{label}</Label>
      <SectionDivider />
      <TagGroup>
        {tags.map(item => (
          <Tag key={item}>{item}</Tag>
        ))}
      </TagGroup>
    </TagContainer>
  );
};

export default ProfileHeader;

import React, { useMemo, useCallback } from 'react';
import { useCurrentInfluencer } from 'hooks/currentInfluencer';
import { useIntl } from 'react-intl';
import { Container, Wordcloud } from './styles';

const WordCloud: React.FC = () => {
  const intl = useIntl();
  const { currentSocial, currentInfluencer } = useCurrentInfluencer();
  const { top_terms } = currentInfluencer.social[currentSocial];
  const words = useMemo(
    () =>
      top_terms.map(item => ({
        text: item.name,
        value: item.amount,
      })),
    [top_terms],
  );

  const wordTooltip = useCallback(
    ({ text, value }: any) => {
      const hasBeenMentioned = intl.formatMessage({
        id: 'profile.social.charts.wordcloud.has-been-mentioned',
      });
      const times = intl.formatMessage({
        id: 'profile.social.charts.wordcloud.times',
      });
      return `'${text}' ${hasBeenMentioned} ${value} ${times}`;
    },
    [intl],
  );

  return (
    <Container>
      <Wordcloud
        words={words}
        callbacks={{
          getWordTooltip: wordTooltip,
        }}
      />
    </Container>
  );
};

export default WordCloud;

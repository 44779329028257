import React, { useEffect, useState, useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Spin } from 'antd';
import Header from 'components/Header';
import Menu from 'components/Menu';
import CurrentInfluencerProvider from 'hooks/currentInfluencer';
import InfluencersProvider from 'hooks/influencers';
import { useAuth } from 'hooks/auth';
import Home from 'pages/Home';
import Profile from 'pages/Profile';
import Favorites from 'pages/Favorites';
import FavoritesProvider from 'hooks/favorites';
import CampaignsProvider from 'hooks/campaigns';
import { Container, Loader } from './styles';
import PrivateRoute from './PrivateRoute';

const Routes: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { authorizeUser, user } = useAuth();

  useEffect(() => {
    authorizeUser();
    setLoading(false);
  }, [authorizeUser]);

  const isGuest = useMemo(
    () => !user?.consumer_type || user?.consumer_type === 'unassigned_costumer',
    [user],
  );

  if (loading) {
    return (
      <Container>
        <Loader>
          <Spin size="large" />
        </Loader>
      </Container>
    );
  }

  return (
    <Container>
      <Header />
      <InfluencersProvider>
        <Menu />
        <FavoritesProvider>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/bbb" component={Home} exact />
            <PrivateRoute
              isAllowed={!isGuest}
              path="/favorites"
              fallbackPath="/"
            >
              <CampaignsProvider>
                <Favorites />
              </CampaignsProvider>
            </PrivateRoute>
            <Route path="/influencers/:id">
              <CurrentInfluencerProvider>
                <Profile />
              </CurrentInfluencerProvider>
            </Route>
          </Switch>
        </FavoritesProvider>
      </InfluencersProvider>
    </Container>
  );
};

export default Routes;

import React, { useState, useEffect } from 'react';
import InfluencersList from 'components/InfluencersList';
import { InfluencerType, FavoriteType } from 'types';
import { Typography, Checkbox, Skeleton } from 'antd';
import { useIntl } from 'react-intl';

import { useFavorites } from 'hooks/favorites';
import { InputSearch, ListContainer } from './styles';

const { Paragraph } = Typography;

interface SearchFavoritesProps {
  checkable?: boolean;
  favoritable?: boolean;
  checkedFavorites?: string[];
  onCheckboxChange?: (values: any) => void;
  photoSize?: 'small' | 'default' | 'large';
}

const SearchFavorites: React.FC<SearchFavoritesProps> = ({
  favoritable,
  checkable,
  onCheckboxChange,
  checkedFavorites,
  photoSize,
}) => {
  const intl = useIntl();
  const { loading, favorites, searchFavoritesByName } = useFavorites();
  const [profiles, setProfiles] = useState([] as FavoriteType[]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setProfiles(favorites);
  }, [favorites]);

  const onSearchChange = (text: string): void => {
    setSearchText(text);
  };

  const searchFavorites = (): void => {
    setProfiles(searchFavoritesByName(searchText));
  };

  if (loading) {
    return <Skeleton active loading={loading} />;
  }

  return (
    <>
      {favorites.length > 15 ? (
        <InputSearch
          enterButton
          placeholder={intl.formatMessage({
            id: 'favorites.search-placeholder',
          })}
          onSearch={searchFavorites}
          onChange={e => onSearchChange(e.target.value)}
        />
      ) : null}
      {favorites.length > 0 ? (
        <ListContainer>
          <Checkbox.Group
            style={{ width: '100%' }}
            onChange={onCheckboxChange}
            defaultValue={checkedFavorites}
          >
            <InfluencersList
              list={profiles as InfluencerType[]}
              checkboxValue={item => item.id}
              favoritable={favoritable}
              checkable={checkable}
              photoSize={photoSize}
            />
          </Checkbox.Group>
        </ListContainer>
      ) : (
        <Paragraph>
          {intl.formatMessage({
            id: 'favorites.search.no-influencers-found',
          })}
        </Paragraph>
      )}
    </>
  );
};

export default SearchFavorites;

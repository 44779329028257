import React from 'react';

import { Typography } from 'antd';
import { useIntl } from 'react-intl';

import SearchFavorites from 'components/SearchFavorites';
import { useFavorites } from 'hooks/favorites';
import exportFavorites from 'utils/exportFavorites';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Container, DownloadButton, TitleContainer } from './styles';

const { Title } = Typography;

const FavoritesList: React.FC = () => {
  const intl = useIntl();
  const { favorites } = useFavorites();

  return (
    <Container>
      <TitleContainer>
        <Title level={4}>
          {intl.formatMessage({
            id: 'favorites.favorite-influencers',
          })}
        </Title>
        <DownloadButton
          onClick={e => {
            e.stopPropagation();
            exportFavorites(favorites);
          }}
        >
          <CloudDownloadOutlined />
        </DownloadButton>
      </TitleContainer>
      <SearchFavorites favoritable photoSize="large" />
    </Container>
  );
};

export default FavoritesList;

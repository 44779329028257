import React, { createContext, useState, useCallback, useContext } from 'react';
import DownloadModal from 'components/Modals/Download';
import ActiveCampaign from 'components/Modals/ActiveCampaign';
import { useAuth } from './auth';
import { useInfluencers } from './influencers';

interface ModalContextInterface {
  openActiveCampaignModal: () => void;

  openDownloadModal: () => void;
}

const ModalContext = createContext<ModalContextInterface>(
  {} as ModalContextInterface,
);

const ModalProvider: React.FC = ({ children }) => {
  const { influencers } = useInfluencers();
  const [download, setDownload] = useState(false);
  const [activeCampaign, setActiveCampaign] = useState(false);
  const { user } = useAuth();
  const openActiveCampaignModal = useCallback(
    () => setActiveCampaign(true),
    [],
  );
  const openDownloadModal = useCallback(
    () =>
      user.consumer_type === 'unassigned_costumer'
        ? setActiveCampaign(true)
        : setDownload(true),
    [user.consumer_type],
  );

  return (
    <ModalContext.Provider
      value={{ openActiveCampaignModal, openDownloadModal }}
    >
      {children}
      <ActiveCampaign
        visible={activeCampaign}
        onCancel={() => setActiveCampaign(false)}
      />
      <DownloadModal
        influencers={influencers}
        visible={download}
        onCancel={() => setDownload(false)}
      />
    </ModalContext.Provider>
  );
};
function useModals(): ModalContextInterface {
  const context = useContext(ModalContext);

  return context;
}

export { ModalProvider, useModals };

/* eslint-disable no-underscore-dangle */
import React, { useState, useMemo } from 'react';
import {
  CaretRightOutlined,
  EditOutlined,
  DeleteOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons';

import { Typography } from 'antd';
import InfluencersList from 'components/InfluencersList';
import { InfluencerType, CampaignType } from 'types';
import { useIntl } from 'react-intl';
import exportFavorites from 'utils/exportFavorites';
import {
  CampaignCollapse,
  CampaignPanel,
  DescriptionHeader,
  PanelTitle,
  InfluencersListContainer,
  MenuButton,
} from './styles';

import DeleteModal from './DeleteModal';
import EditModal from './EditModal';

const { Title, Paragraph } = Typography;

interface CampaignCardProps {
  campaign: CampaignType;
}

const CampaignCard: React.FC<CampaignCardProps> = ({ campaign }) => {
  const intl = useIntl();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const panelActions = useMemo(
    () => (
      <>
        <Button
          icon={<EditOutlined />}
          onClick={e => {
            e.stopPropagation();
            setOpenEditModal(true);
          }}
        />
        <Button
          icon={<DeleteOutlined />}
          onClick={e => {
            e.stopPropagation();
            setOpenDeleteModal(true);
          }}
        />
        <Button
          onClick={e => {
            e.stopPropagation();
            exportFavorites(campaign.campaign_favorites);
          }}
          icon={<CloudDownloadOutlined />}
        />
      </>
    ),
    [campaign.campaign_favorites],
  );

  return (
    <CampaignCollapse
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      <CampaignPanel
        header={<PanelTitle>{campaign.name}</PanelTitle>}
        key={campaign._id}
        extra={panelActions}
      >
        <DescriptionHeader>
          <Title level={4}>
            {intl.formatMessage({
              id: 'campaigns.description',
            })}
          </Title>
          <span>
            {intl.formatMessage({
              id: 'campaigns.created-on',
            })}
            {new Date(campaign.created_at).toLocaleDateString(
              intl.formatMessage({ id: 'general.locale' }),
            )}
          </span>
        </DescriptionHeader>
        <Paragraph>{campaign.description}</Paragraph>

        <Title level={4}>Influencers</Title>
        <InfluencersListContainer>
          {campaign.campaign_favorites.length > 0 ? (
            <InfluencersList
              list={campaign.campaign_favorites as InfluencerType[]}
            />
          ) : (
            <Paragraph>
              {intl.formatMessage({
                id: 'campaigns.modal.no-influencers',
              })}
            </Paragraph>
          )}
        </InfluencersListContainer>
      </CampaignPanel>
      <DeleteModal
        visible={openDeleteModal}
        onCancel={() => setOpenDeleteModal(false)}
        campaignId={campaign._id}
      />
      <EditModal
        visible={openEditModal}
        onCancel={() => setOpenEditModal(false)}
        onOk={() => setOpenEditModal(false)}
        campaign={campaign}
      />
    </CampaignCollapse>
  );
};

interface ButtonProps {
  icon: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const Button: React.FC<ButtonProps> = ({ icon, onClick }) => {
  return <MenuButton onClick={onClick}>{icon}</MenuButton>;
};

export default CampaignCard;

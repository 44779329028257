import React, { useMemo } from 'react';
import { Typography, Table, Modal } from 'antd';
import { useIntl } from 'react-intl';
import { InfoCircleOutlined } from '@ant-design/icons';

import { ModalTitle } from './styles';

const { Text, Paragraph } = Typography;

interface InfoModalProps {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const InfoModal: React.FC<InfoModalProps> = ({ visible, onOk, onCancel }) => {
  const intl = useIntl();

  const columnsHeader = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: 'menu.searchbar.info.table.operator' }),
        dataIndex: 'operator',
        key: 'operator',
      },
      {
        title: intl.formatMessage({ id: 'menu.searchbar.info.table.example' }),
        dataIndex: 'example',
        key: 'example',
      },
      {
        title: intl.formatMessage({ id: 'menu.searchbar.info.table.result' }),
        dataIndex: 'result',
        key: 'result',
      },
    ],
    [intl],
  );

  return (
    <Modal
      visible={visible}
      width={650}
      centered
      onOk={onOk}
      onCancel={onCancel}
      cancelText
    >
      <ModalTitle level={4}>
        <InfoCircleOutlined />
        {intl.formatMessage({ id: 'menu.searchbar.info.title' })}
      </ModalTitle>
      <Paragraph>
        {intl.formatMessage({ id: 'menu.searchbar.info.tip-one' })}
      </Paragraph>
      <Paragraph>
        {intl.formatMessage({ id: 'menu.searchbar.info.tip-two' })}
      </Paragraph>
      <Text strong>
        {intl.formatMessage({ id: 'menu.searchbar.info.common' })}
      </Text>
      <Table
        dataSource={[
          {
            operator: '-',
            example: 'happy -hour',
            result: intl.formatMessage({
              id: 'menu.searchbar.info.table.result.hyphen',
            }),
          },
        ]}
        columns={columnsHeader}
        pagination={false}
      />
      <br />
      <Text strong>
        {intl.formatMessage({ id: 'menu.searchbar.info.boolean' })}
      </Text>
      <Table
        dataSource={[
          {
            operator: 'OR',
            example: 'happy OR hour',
            result: intl.formatMessage({
              id: 'menu.searchbar.info.table.result.or',
            }),
          },
          {
            operator: 'AND',
            example: 'happy AND hour',
            result: intl.formatMessage({
              id: 'menu.searchbar.info.table.result.and',
            }),
          },
          {
            operator: 'NOT',
            example: 'happy NOT hour',
            result: intl.formatMessage({
              id: 'menu.searchbar.info.table.result.not',
            }),
          },
        ]}
        columns={columnsHeader}
        pagination={false}
      />
    </Modal>
  );
};

export default InfoModal;

/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';

import { Typography, Skeleton } from 'antd';
import { useIntl } from 'react-intl';
import { useCampaigns } from 'hooks/campaigns';
import { NewCampaign } from './styles';

import CreateModal from './CreateModal';
import CampaignCard from './CampaignCard';

const { Title } = Typography;

const CampaignsContainer: React.FC = () => {
  const intl = useIntl();
  const { loading, campaigns } = useCampaigns();
  const [openCreateModal, setOpenCreateModal] = useState(false);

  if (loading) {
    return <Skeleton active loading={loading} />;
  }

  return (
    <div>
      <NewCampaign onClick={() => setOpenCreateModal(!openCreateModal)}>
        <Title level={4}>
          <PlusOutlined />
          &nbsp;
          {intl.formatMessage({
            id: 'campaigns.create-new',
          })}
        </Title>
      </NewCampaign>
      {campaigns.map(campaign => (
        <CampaignCard campaign={campaign} key={campaign._id} />
      ))}
      <CreateModal
        visible={openCreateModal}
        onCancel={() => setOpenCreateModal(false)}
        onOk={() => setOpenCreateModal(false)}
      />
    </div>
  );
};

export default CampaignsContainer;

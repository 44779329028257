import React from 'react';
import { IntlProvider } from 'react-intl';
import { useMemo } from 'react';
import { useAuth } from './auth';
import ptBRTranslation from '../translations/pt-BR.json';
import ptPTTranslation from '../translations/pt-PT.json';
import enTranslation from '../translations/en.json';
import esTranslation from '../translations/es.json';

const I18nProvider: React.FC = ({ children }) => {
  const { user } = useAuth();

  const language = useMemo(() => {
    if (user?.language?.toString() == 'pt_PT') {
      return 'pt-PT';
    } else if (user?.language?.toString() == 'pt_BR') {
      return 'pt-BR';
    } else {
      return user?.language;
    }
  }, [user]);

  const messages = useMemo(() => {
    const translations = {
      'pt-BR': ptBRTranslation,
      'pt-PT': ptPTTranslation,
      'en': enTranslation,
      'es': esTranslation,
    };
    return translations[language || 'en'];
  }, [user]);

  return (
    <IntlProvider locale={language || 'en'} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export { I18nProvider };

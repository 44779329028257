import styled from 'styled-components';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { ReactComponent as BookMarkIcon } from 'assets/icons/bookmark.svg';

export const Container = styled.div<{ checked: boolean }>`
  font-size: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-top: 1px solid;

  stroke: ${props =>
    props.checked
      ? props.theme.colors.primary
      : props.theme.colors.tertiaryDark};

  color: ${props =>
    props.checked
      ? props.theme.colors.primary
      : props.theme.colors.tertiaryDark};

  :hover {
    color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
    stroke: ${props => props.theme.colors.primary};
  }
`;

export const HeartIconOutlined = styled(HeartOutlined)``;

export const HeartIconFilled = styled(HeartFilled)``;

export const FavoriteButtonContainer = styled.div`
  position: absolute;
`;

export const BookMarkIconContainer = styled(BookMarkIcon)`
  fill: transparent;
  width: auto;
  height: 30px;
  z-index: 1;
`;

import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  height: 57px;
  position: fixed;
  background-color: black;
  display: flex;
  justify-content: center;
  z-index: 10;
`;

export const Image = styled.img`
  height: 35px;
  margin-top: 7px;
`;

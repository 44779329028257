import React from 'react';
import { useIntl } from 'react-intl';
import { useCurrentInfluencer } from 'hooks/currentInfluencer';
import EvolutionChart from './EvolutionChart';
import { Container } from './styles';

const EvolutionCharts: React.FC = () => {
  const intl = useIntl();
  const { currentSocial, currentInfluencer } = useCurrentInfluencer();
  const {
    social_network,
    followers_evolution,
    interactions_per_1k_followers_evolution: interactions_evolution,
  } = currentInfluencer.social[currentSocial];
  return (
    <Container>
      {followers_evolution.length > 0 && (
        <EvolutionChart
          data={followers_evolution}
          label={ (social_network != 'youtube') ? intl.formatMessage({ id: 'profile.social.followers' }) : intl.formatMessage({ id: 'profile.social.subscribers' }) }
          dataKey="amount"
          dark
          colorA="#F6759A"
          colorB="#CF75FD"
          backgroundColor="#2D2666"
        />
      )}
      {interactions_evolution.length > 0 && social_network != 'youtube' && (
        <EvolutionChart
          data={interactions_evolution}
          label={intl.formatMessage({
            id: 'profile.social.interaction-per-1k',
          })}
          dataKey="amount"
          colorA="#017BDF"
          colorB="#58EABD"
        />
      )}
    </Container>
  );
};

export default EvolutionCharts;

import React, { useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import Modal, { ModalProps } from 'antd/lib/modal';
import api from 'services/crawlerApi';

import { Input, Spin, Checkbox, message } from 'antd';
import { InfluencerType } from 'types';
import { useParams } from 'react-router-dom';

import InfluencersList from 'components/InfluencersList';
import {
  InfiniteScrollContainer,
  LoadingContainer,
  ProfilesSelected,
} from './styles';

const { Search } = Input;

const MergeModal: React.FC<ModalProps> = ({
  visible,
  onCancel,
  onOk,
  ...rest
}) => {
  const { id } = useParams();
  const [profiles, setProfiles] = useState([] as InfluencerType[]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [profilesToMerge, setProfilesToMerge] = useState(
    [] as InfluencerType[],
  );
  const { confirm } = Modal;

  const handleSearchChange = useCallback((e): void => {
    setSearchText(e.target.value);
  }, []);

  const handleCheckboxChange = useCallback(checkedList => {
    setProfilesToMerge(checkedList);
  }, []);

  const handleSubmit = (): void => {
    confirm({
      title: 'This action will merge the influencers selected!',
      content: (
        <>
          <p>Profiles selected:</p>
          <ProfilesSelected>
            <InfluencersList list={profilesToMerge} />
          </ProfilesSelected>
          <span>Are you sure you want to continue ?</span>
        </>
      ),
      onOk: e => {
        const profileIds = profilesToMerge.map(item => item.id);
        api
          .post(`/influencers/merge/${id}`, { ids: profileIds })
          .then(response => {
            message.success('Profiles merged with success!');
            Modal.destroyAll();
            if (onOk) onOk(e);
          })
          .catch(error => {
            message.error('Error merging the profiles, try again!');
            Modal.destroyAll();
          });
      },
    });
  };

  const handleCancel = useCallback(
    e => {
      setLoading(false);
      setHasMore(true);
      setSearchText('');
      setProfiles([] as InfluencerType[]);
      setProfilesToMerge([] as InfluencerType[]);
      Modal.destroyAll();
      if (onCancel) onCancel(e);
    },
    [onCancel],
  );

  const searchInfluencers = useCallback(
    (new_search?: boolean) => {
      setLoading(true);
      if (new_search) {
        setHasMore(true);
      }
      api
        .get<{
          data: Array<InfluencerType>;
        }>('/influencers/search', {
          params: {
            page: new_search ? 0 : page,
            searchFor: searchText,
            whereToSearch: JSON.stringify(['username', 'name']),
            socialNetworks: JSON.stringify([
              'instagram',
              'facebook',
              'twitter',
              'youtube',
            ]),
            min_followers: 1000,
            max_followers: 1000000000,
            gender: JSON.stringify(['M', 'F', 'ORG']),
            languages: '[]',
            age: '[]',
            countries: '[]',
            interests: '[]',
          },
        })
        .then(response => {
          if (response.data.data.length < 12) {
            setHasMore(false);
          }
          setLoading(false);
          const newProfiles = [...response.data.data].filter(
            profile => profile.id !== id,
          );
          if (new_search) {
            setPage(1);
            // eslint-disable-next-line no-unused-expressions
            document.getElementById('infinite-scroll')?.scrollIntoView();
            setProfilesToMerge([]);
            return setProfiles(newProfiles);
          }
          setPage(page + 1);
          return setProfiles([...profiles, ...newProfiles]);
        });
    },
    [page, searchText, profiles, id],
  );

  return (
    <Modal
      destroyOnClose
      {...rest}
      cancelText="Cancel"
      visible={visible}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okButtonProps={{ disabled: profilesToMerge.length < 1 }}
      title="Merge Influencer"
    >
      <Search
        placeholder="Search for an influencer"
        enterButton
        onSearch={() => searchInfluencers(true)}
        onChange={e => handleSearchChange(e)}
      />
      {profiles.length > 0 && (
        <InfiniteScrollContainer>
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={() => searchInfluencers()}
            hasMore={!loading && hasMore}
            useWindow={false}
            id="infinite-scroll"
          >
            <Checkbox.Group
              style={{ width: '100%' }}
              onChange={handleCheckboxChange}
            >
              {loading && (
                <LoadingContainer>
                  <Spin />
                </LoadingContainer>
              )}
              <InfluencersList list={profiles} checkable />
            </Checkbox.Group>
          </InfiniteScroll>
        </InfiniteScrollContainer>
      )}
    </Modal>
  );
};

export default MergeModal;

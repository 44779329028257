import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { useCurrentInfluencer } from 'hooks/currentInfluencer';

import { ReactComponent as GraphCircularIcon } from 'assets/icons/graph-circular.svg';
import { ReactComponent as GraphForceIcon } from 'assets/icons/graph-force.svg';

import GraphChart from './GraphChart';
import RankingList from './RankingList';
import {
  Container,
  RankingChart,
  Label,
  SmallChartsContainer,
  WordCloudContainer,
  GraphChartContainer,
  NoData,
  GraphOption,
} from './styles';
import WordCloud from './WordCloud';

const RankingCharts: React.FC = () => {
  const intl = useIntl();
  const [graphType, setGraphType] = useState<'force' | 'circular'>('circular');
  const { currentSocial, currentInfluencer } = useCurrentInfluencer();
  const {
    top_mentions,
    top_hashtags,
    top_terms,
    top_entities,
  } = currentInfluencer.social[currentSocial];

  return (
    <Container>
      {top_terms.length > 0 && (
        <WordCloudContainer>
          <Label>
            {intl.formatMessage({ id: 'profile.social.charts.top-terms' })}
          </Label>
          <WordCloud />
        </WordCloudContainer>
      )}
      {top_entities.length > 1 && (
        <GraphChartContainer>
          <Label>
            {intl.formatMessage({ id: 'profile.social.charts.top-entities' })}
            <GraphOption
              onClick={() => setGraphType('circular')}
              active={graphType === 'circular'}
            >
              <GraphCircularIcon />
            </GraphOption>
            <GraphOption
              onClick={() => setGraphType('force')}
              active={graphType === 'force'}
            >
              <GraphForceIcon />
            </GraphOption>
          </Label>

          <GraphChart graphType={graphType} />
        </GraphChartContainer>
      )}
      <SmallChartsContainer>
        <RankingChart>
          <Label>
            {intl.formatMessage({ id: 'profile.social.charts.top-hashtags' })}
          </Label>
          {top_hashtags.length > 0 ? (
            <RankingList data={top_hashtags} />
          ) : (
            <NoData>
              <Label>
                {intl.formatMessage({
                  id: 'profile.social.charts.error.no-hashtags-found',
                })}
              </Label>
            </NoData>
          )}
        </RankingChart>
        <RankingChart>
          <Label>
            {intl.formatMessage({ id: 'profile.social.charts.top-mentions' })}
          </Label>
          {top_mentions.length > 0 ? (
            <RankingList data={top_mentions} />
          ) : (
            <NoData>
              <Label>
                {intl.formatMessage({
                  id: 'profile.social.charts.error.no-mentions-found',
                })}
              </Label>
            </NoData>
          )}
        </RankingChart>
      </SmallChartsContainer>
    </Container>
  );
};

export default RankingCharts;

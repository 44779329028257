import React, { useCallback, useMemo } from 'react';

import { Avatar, Typography, Popover } from 'antd';
import { UserOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { useCurrentInfluencer } from 'hooks/currentInfluencer';

import { useIntl } from 'react-intl';
import { Container, Content, ContentHeader, PopoverContainer } from './styles';

const { Paragraph } = Typography;

const ProfileBio: React.FC = () => {
  const { currentSocial, currentInfluencer } = useCurrentInfluencer();
  const {
    avatar,
    username,
    bio,
    social_network,
    id,
    last_crawled_at,
  } = currentInfluencer.social[currentSocial];

  const networksLinks = useCallback((social: string): string => {
    if (social === 'facebook') return 'https://www.facebook.com/';
    if (social === 'instagram') return 'https://www.instagram.com/';
    if (social === 'twitter') return 'https://www.twitter.com/';
    if (social === 'youtube') return 'https://www.youtube.com/channel/';
    return 'https://';
  }, []);

  const profileUsername = useMemo(
    () => (social_network === 'facebook' ? currentInfluencer.name : username),
    [currentInfluencer.name, social_network, username],
  );

  return (
    <Container>
      <a
        href={`${networksLinks(social_network)}${
          social_network === 'youtube' ? id : username
        }`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Avatar size={116} icon={<UserOutlined />} src={avatar} />
      </a>
      <Content>
        <ContentHeader>
          <a
            href={`${networksLinks(social_network)}${
              social_network === 'youtube' ? id : username
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>{profileUsername}</h6>
          </a>
          <Popover
            placement="rightTop"
            content={<InfoPopover updatedAt={last_crawled_at} />}
            trigger="hover"
          >
            <InfoCircleOutlined style={{ fontSize: '18px' }} />
          </Popover>
        </ContentHeader>
        <Paragraph ellipsis={{ rows: 4, expandable: false }}>{bio}</Paragraph>
      </Content>
    </Container>
  );
};

interface InfoPopoverProps {
  updatedAt: string;
}

const InfoPopover: React.FC<InfoPopoverProps> = ({ updatedAt }) => {
  const intl = useIntl();
  const date = useMemo(
    () =>
      new Intl.DateTimeFormat(
        intl.formatMessage({ id: 'general.locale' }),
      ).format(new Date(updatedAt)),
    [intl, updatedAt],
  );

  return (
    <PopoverContainer>
      <p>
        {intl.formatMessage({ id: 'profile.social.bio.info.updated-at' })}{' '}
        {date}
      </p>
      <p>{intl.formatMessage({ id: 'profile.social.bio.info.all-data' })}</p>
    </PopoverContainer>
  );
};

export default ProfileBio;

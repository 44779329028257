import styled from 'styled-components';
import { Card } from 'antd';
import { roundedCorners } from 'styles/reuse';

export const NewCampaign = styled(Card).attrs({})`
  ${roundedCorners};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 64px;
  background-color: transparent;
  width: 100%;
  margin-bottom: 18px;
  border-color: ${props => props.theme.colors.primary};
  .ant-typography {
    margin: 0;
    color: ${props => props.theme.colors.primary};
    transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.primary};
    .ant-typography {
      color: white;
    }
  }
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

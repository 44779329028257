import { DefaultTheme } from 'styled-components';

const buzzmonitorTheme: DefaultTheme = {
  colors: {
    primary: '#008EED',
    secondary: '#FCB315',
    tertiary: '#969fb7',

    primaryLight: '#4DAEFF',
    primaryDark: '#478DDA',
    primaryDarker: '#327BDD',

    secondaryLight: '#FFCF67',
    secondaryDark: '#E9A256',
    secondaryDarker: '#FF992D',

    tertiaryLight: '#ECEBEB',
    tertiaryDark: '#CCCCCC',

    black: '#282828',
    grey: '#cccccc',
    lighgrey: '#ecebeb',

    success: '#59cb59',
    error: '#DD5b70',

    background: '#F5f5f5',
  },
  size: {
    borderRadius: '4px',
  },
};

export default buzzmonitorTheme;

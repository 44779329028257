import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { FiltersType } from 'hooks/influencers';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Popover } from 'antd';

import InfoModal from './InfoModal';

import { Container, InputSearch, SearchContainer } from './styles';

interface SearchProps {
  filters: FiltersType;
  onSearch: (newFilters: FiltersType) => void;
}

const Search: React.FC<SearchProps> = ({ filters, onSearch }) => {
  const intl = useIntl();
  const history = useHistory();
  const [form] = Form.useForm();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [hasBooleanSearch, setHasBooleanSearch] = useState(false);

  const onFinish = useCallback(() => {
    form
      .validateFields()
      .then(values => onSearch({ ...values } as FiltersType))
      .then(() => {
        if (window.location.pathname !== '/') history.push('/');
        window.scrollTo(0, 0);
      });
  }, [form, history, onSearch]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const hasBooleanTerms = e.target.value.match(/(")|(OR)|(AND)|(NOT)/g);
    if (hasBooleanTerms) {
      if (!hasBooleanSearch) {
        setHasBooleanSearch(true);
      }
    } else if (hasBooleanSearch) setHasBooleanSearch(false);
  };

  return (
    <Container
      form={form}
      onFinish={onFinish}
      layout="vertical"
      initialValues={{ searchFor: filters.searchFor }}
    >
      <SearchContainer>
        <Popover
          content={intl.formatMessage({
            id: 'menu.searchbar.tooltip.boolean-mode',
          })}
          visible={hasBooleanSearch}
        >
          <Form.Item
            name="searchFor"
            style={{ margin: '0 12px 0 0', width: '100%' }}
          >
            <InputSearch
              placeholder={intl.formatMessage({
                id: 'menu.searchbar.placeholder',
              })}
              enterButton
              suffix={
                <InfoCircleOutlined
                  onClick={() => setShowInfoModal(!showInfoModal)}
                />
              }
              onSearch={onFinish}
              onChange={e => handleSearchChange(e)}
            />
          </Form.Item>
        </Popover>
      </SearchContainer>
      <InfoModal
        visible={showInfoModal}
        onOk={() => setShowInfoModal(false)}
        onCancel={() => setShowInfoModal(false)}
      />
    </Container>
  );
};

export default Search;

import React, { useCallback, useState, useMemo } from 'react';
import Modal, { ModalProps } from 'antd/lib/modal';
import { useIntl } from 'react-intl';
import { saveAs } from 'file-saver';
import XLSX from 'xlsx';
import {
  Form,
  Button,
  Upload,
  message,
  Input,
  Typography,
  Row,
  Col,
} from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import api from 'services/crawlerApi';
import { useAuth } from 'hooks/auth';

const { Text, Paragraph } = Typography;

const AddInfluencerModal: React.FC<ModalProps> = ({ ...rest }) => {
  const intl = useIntl();

  const downloadExampleSheet = useCallback(() => {
    const parsed_data = [
      ['influencer1', 'https://www.facebook.com/influencer1', 'facebook'],
      ['influencer2', 'https://www.instagram.com/influencer2', 'instagram'],
      ['influencer3', 'https://www.youtube.com/c/influencer3', 'youtube'],
      ['influencer4', 'https://twitter.com/influencer4', 'twitter'],
    ];
    const columns = ['username', 'url', 'social_network'];
    const sheetArrays = [columns, ...parsed_data];
    const sheetName = 'influencers_example';
    const sheet = XLSX.utils.aoa_to_sheet(sheetArrays);
    const csvSheet = XLSX.utils.sheet_to_csv(sheet);
    const blob = new Blob([csvSheet], { type: 'text/csv' });
    return saveAs(blob, `${sheetName.split(' ').join('_')}.csv`);
  }, []);

  return (
    <Modal
      destroyOnClose
      {...rest}
      title={intl.formatMessage({
        id: 'menu.modal.add-influencer.title',
      })}
      footer={null}
    >
      <SingleInfluencerForm />
      <MultipleInfluencerForm />

      <Text strong>
        {intl.formatMessage({
          id: 'menu.modal.add-influencer.warning',
        })}
      </Text>
      <Paragraph>
        &bull;
        {intl.formatMessage({
          id: 'menu.modal.add-influencer.warning.moderation-process',
        })}
      </Paragraph>
      <Paragraph>
        &bull;
        {intl.formatMessage({
          id: 'menu.modal.add-influencer.warning.file-config',
        })}
        <Button
          type="link"
          icon={<DownloadOutlined />}
          size="small"
          onClick={() => downloadExampleSheet()}
        >
          {intl.formatMessage({
            id: 'menu.modal.add-influencer.warning.download-example',
          })}
        </Button>
      </Paragraph>
    </Modal>
  );
};

const SingleInfluencerForm: React.FC = () => {
  const intl = useIntl();
  const { token } = useAuth();
  const [loadingSingleInfluencer, setLoadingSingleInfluencer] = useState(false);

  const submitSingleInfluencer = useCallback(
    values => {
      setLoadingSingleInfluencer(true);
      api
        .post('/influencers/crawler/profile', values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          message.success(
            intl.formatMessage({
              id: 'menu.modal.add-influencer.single.success',
            }),
          );
        })
        .catch(error => {
          const errorName = error.response.data.status;
          message.error(
            intl.formatMessage({
              id: `menu.modal.add-influencer.single.fail.${errorName}`,
            }),
          );
        })
        .finally(() => {
          setLoadingSingleInfluencer(false);
        });
    },
    [intl, token],
  );

  return (
    <Form layout="vertical" onFinish={submitSingleInfluencer}>
      <Text strong>
        {intl.formatMessage({
          id: 'menu.modal.add-influencer.single.description1',
        })}
      </Text>
      <Paragraph>
        {intl.formatMessage({
          id: 'menu.modal.add-influencer.single.description2',
        })}
      </Paragraph>
      <Form.Item
        name="url"
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'menu.modal.add-influencer.single.error',
            }),
          },
        ]}
      >
        <Input placeholder="Ex.: https://twitter.com/username" />
      </Form.Item>
      <Form.Item>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingSingleInfluencer}
            >
              {intl.formatMessage({
                id: 'menu.modal.add-influencer.single.submit',
              })}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

const MultipleInfluencerForm: React.FC = () => {
  const intl = useIntl();
  const { token } = useAuth();
  const [uploadedFiles, setUploadedFiles] = useState<UploadFile[]>();
  const [loadingMultipleInfluencer, setLoadingMultipleInfluencer] = useState(
    false,
  );

  const uploadProps = useMemo(
    () => ({
      name: 'file',
      accept: '.csv,.xlsx',
      headers: {
        Authorization: `Bearer ${token}`,
        authorization: 'authorization-text',
      },
      multiple: false,
      action: 'https://crawler.influencers.buzzmonitor.com.br/influencers/crawler/import',
      onChange(info: UploadChangeParam<UploadFile<any>>) {
        if (info.file.status === 'done') {
          message.success(
            intl.formatMessage({
              id: 'menu.modal.add-influencer.multiple.upload.success',
            }),
          );
        } else if (info.file.status === 'error') {
          message.error(
            intl.formatMessage({
              id: 'menu.modal.add-influencer.multiple.upload.fail',
            }),
          );
        }
        const fileList = [...info.fileList].slice(-1);
        setUploadedFiles(fileList || []);
      },
    }),
    [intl, token],
  );

  const submitMultipleInfluencers = useCallback(
    values => {
      setLoadingMultipleInfluencer(true);
      const data = new FormData();
      data.append('file', values.file.file.originFileObj);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'multipart/form-data',
        },
      };
      api
        .post('/influencers/crawler/import', data, config)
        .then(() => {
          message.success(
            intl.formatMessage({
              id: 'menu.modal.add-influencer.multiple.success',
            }),
          );
        })
        .catch(() => {
          message.error(
            intl.formatMessage({
              id: 'menu.modal.add-influencer.multiple.fail',
            }),
          );
        })
        .finally(() => {
          setLoadingMultipleInfluencer(false);
        });
    },
    [intl, token],
  );

  return (
    <Form layout="vertical" onFinish={submitMultipleInfluencers}>
      <Text strong>
        {intl.formatMessage({
          id: 'menu.modal.add-influencer.multiple.description1',
        })}
      </Text>
      <Paragraph>
        {intl.formatMessage({
          id: 'menu.modal.add-influencer.multiple.description2',
        })}
      </Paragraph>
      <Form.Item
        name="file"
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'menu.modal.add-influencer.multiple.upload.error',
            }),
          },
        ]}
      >
        <Upload {...uploadProps} fileList={uploadedFiles}>
          <Button icon={<UploadOutlined />} style={{ width: '100%' }}>
            {intl.formatMessage({
              id: 'menu.modal.add-influencer.multiple.upload.button',
            })}
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingMultipleInfluencer}
            >
              {intl.formatMessage({
                id: 'menu.modal.add-influencer.multiple.submit',
              })}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default AddInfluencerModal;

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ProfileHeader from 'components/ProfileHeader';
import ProfileSocial from 'components/ProfileSocial';
import { useCurrentInfluencer } from 'hooks/currentInfluencer';

import { Container } from './styles';

const Profile: React.FC = () => {
  const { id } = useParams();
  const { loadInfluencerData } = useCurrentInfluencer();

  useEffect(() => {
    loadInfluencerData(id);
  }, [id, loadInfluencerData]);

  return (
    <Container>
      <ProfileHeader />
      <ProfileSocial />
    </Container>
  );
};

export default Profile;

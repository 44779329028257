import styled from 'styled-components';

export const Container = styled.div<{ loading?: boolean }>`
  display: grid;
  width: 100%;
  grid-gap: 24px;
  transition-delay: 500s;
  transition: width ease 1s;
  margin-top: ${props => (props.loading ? '24px' : '0px')};
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;
  }
`;
export const Loader = styled.div`
  display: grid;
  place-items: center;
  padding: 24px 0;
`;

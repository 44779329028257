import React, { useEffect } from 'react';
import CampaignsContainer from 'components/CampaignsContainer';
import FavoritesList from 'components/FavoritesList';
import { useCampaigns } from 'hooks/campaigns';
import { Container } from './styles';

const Favorites: React.FC = () => {
  const { loadCampaigns } = useCampaigns();

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  return (
    <Container>
      <FavoritesList />
      <CampaignsContainer />
    </Container>
  );
};

export default Favorites;

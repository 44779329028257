import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface PrivateRouteProps extends RouteProps {
  isAllowed: boolean;
  fallbackPath: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  isAllowed,
  fallbackPath,
  ...rest
}) => {
  if (isAllowed) {
    return <Route {...rest} />;
  }
  return <Route render={() => <Redirect to={fallbackPath} />} />;
};

export default PrivateRoute;

import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { uuid } from 'uuidv4';

import { useCurrentInfluencer } from 'hooks/currentInfluencer';
import formatNumber from 'utils/formatNumber';
import fixNumberDigits from 'utils/fixNumberDigits';

import { Container, MetricContainer } from './styles';

const Metrics: React.FC = () => {
  const { currentSocial, currentInfluencer } = useCurrentInfluencer();
  const {
    social_network,
    followers,
    posts_count,
    mean_interactions_per_1k_followers_evolution: interations_evolution,
  } = currentInfluencer.social[currentSocial];
  const intl = useIntl();
  return (
    <Container>
      {followers ? (
        <Metric
          key={`metric-${uuid()}`}
          value={followers}
          label={
            social_network == 'youtube'
              ? intl.formatMessage({ id: 'profile.social.subscribers' })
              : social_network == 'facebook'
                ? intl.formatMessage({ id: 'profile.social.fans' })
                : intl.formatMessage({ id: 'profile.social.followers' })}
        />
      ) : null}
      {posts_count && (social_network != 'facebook') ? (
        <Metric
          key={`metric-${uuid()}`}
          value={posts_count}
          label={intl.formatMessage({ id: 'profile.social.posts' })}
        />
      ) : null}
      {interations_evolution && (social_network != 'youtube') ? (
        <Metric
          key={`metric-${uuid()}`}
          value={interations_evolution || 0}
          label={intl.formatMessage({
            id: 'profile.social.mean_interaction-per-1k',
          })}
        />
      ) : null}
    </Container>
  );
};

interface MetricProps {
  value: number;
  label: string;
}

const Metric: React.FC<MetricProps> = ({ value = 0, label }) => {
  const formattedMetric = useMemo(
    () => formatNumber(fixNumberDigits(value) || 0),
    [value],
  );

  return (
    <MetricContainer>
      <strong>{formattedMetric}</strong>
      <br />
      {label}
    </MetricContainer>
  );
};

export default Metrics;

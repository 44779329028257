import React, { createContext, useState, useContext, useCallback } from 'react';

import api from 'services/crawlerApi';
import { InfluencerType } from 'types';
import formatGenderArray from 'utils/formatGenderArray';

export interface FiltersType {
  searchFor: string;
  socialNetworks: string[];
  whereToSearch: string[];
  gender: string[];
  languages: string[];
  age: string;
  min_followers: number;
  max_followers: number;
  page: number;
  countries: string[];
  interests: string[];
}

interface ContextProps {
  influencers: InfluencerType[];
  filters: FiltersType;
  profilesFound: number;
  loading: boolean;
  hasMore: boolean;
  setInfluencers: (influencer: InfluencerType[]) => void;
  setFilters: (filters: FiltersType) => void;
  searchInfluencers: () => void;
  newSearch: (new_filters: Partial<FiltersType>) => void;
}

const DEFAULT_FILTERS = {
  searchFor: '',
  socialNetworks: ['instagram', 'facebook', 'twitter', 'youtube'],
  whereToSearch: [
    'username',
    'name',
    'top_hashtags',
    'top_terms',
    'top_entities',
    'top_mentions',
    'bio',
  ],
  min_followers: 1000,
  max_followers: 1000000000,
  gender: ['PER', 'ORG'],
  languages: [],
  age: '[]',
  page: 0,
  countries: [],
  interests: [],
};

const InfluencersContext = createContext<ContextProps>({} as ContextProps);

const InfluencersProvider: React.FC = ({ children }) => {
  const [influencers, setInfluencers] = useState<InfluencerType[]>(
    [] as InfluencerType[],
  );

  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [profilesFound, setProfilesFound] = useState(0);

  const [filters, setFilters] = useState<FiltersType>(DEFAULT_FILTERS);

  const formatInfluencersFilters = useCallback((data: FiltersType) => {
    return {
      ...data,
      searchFor: data.searchFor.replace(/"/g, '""'),
      whereToSearch: JSON.stringify(data.whereToSearch),
      socialNetworks: JSON.stringify(data.socialNetworks),
      gender: JSON.stringify(formatGenderArray(data.gender)),
      languages: JSON.stringify(data.languages),
      countries: JSON.stringify(data.countries),
      interests: JSON.stringify(data.interests),
    };
  }, []);

  const newSearch = useCallback(
    (new_filters: Partial<FiltersType>) => {
      setLoading(true);
      setInfluencers([]);
      setHasMore(true);

      const formattedFilters = { ...DEFAULT_FILTERS, ...new_filters, page: 1 };
      setFilters(formattedFilters);

      api
      .get<{
        info: { profiles_found: number };
        data: Array<InfluencerType>;
      }>('/influencers/search', {
        params: formatInfluencersFilters({ ...formattedFilters, page: 0 }),
      })
      .then(response => {
        setProfilesFound(response.data.info.profiles_found);
        if (response.data.data.length < 12) {
          setHasMore(false);
        }
        setInfluencers(response.data.data);
      })
      .finally(() =>  {
        setLoading(false)
      }
      ).catch(() => {
        setLoading(false)
        setHasMore(false)
        setInfluencers([])
        setProfilesFound(0)
      });
  
    },
    [formatInfluencersFilters],
  );

  const searchInfluencers = useCallback(() => {
    setLoading(true);
    api
      .get<{ info: { profiles_found: number }; data: Array<InfluencerType> }>(
        '/influencers/search/',
        {
          params: formatInfluencersFilters(filters),
        },
      )
      .then(res => {
        if (
          res.data.data.length < 12 ||
          influencers.length === res.data.info.profiles_found
        ) {
          setHasMore(false);
        }

        setFilters({ ...filters, page: filters.page + 1 });
        setInfluencers([...influencers, ...res.data.data]);
      })
      .finally(() => {
        setLoading(false)
      }).catch(() => {
        setLoading(false)
        setHasMore(false)
        setInfluencers([])
        setProfilesFound(0)
      });
        
  }, [filters, formatInfluencersFilters, influencers]);

  return (
    <InfluencersContext.Provider
      value={{
        influencers,
        filters,
        profilesFound,
        loading,
        hasMore,
        newSearch,
        setInfluencers,
        setFilters,
        searchInfluencers,
      }}
    >
      {children}
    </InfluencersContext.Provider>
  );
};

export function useInfluencers(): ContextProps {
  const context = useContext(InfluencersContext);

  if (!context) {
    throw new Error(
      'useInfluencers must be used within an InfluencersProvider!',
    );
  }

  return context;
}

export default InfluencersProvider;

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { AuthProvider } from './auth';
import { I18nProvider } from './i18n';

import buzzmonitorTheme from '../styles/theme/buzzmonitor';
import { ModalProvider } from './modals';

const AppProvider: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={buzzmonitorTheme}>
      <AuthProvider>
        <I18nProvider>
          <ModalProvider>{children}</ModalProvider>
        </I18nProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default AppProvider;

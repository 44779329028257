import { AreaChart } from 'recharts';
import styled from 'styled-components';

import { roundedCorners, defaultBorder } from 'styles/reuse';

export const Container = styled.div<{ backgroundColor: string }>`
  ${roundedCorners};
  ${defaultBorder};
  border-bottom: 0px;
  max-width: 100%;
  width: 100%;
  height: 150px;
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 2px 8px;
`;

export const Statistic = styled.span<{ dark: boolean }>`
  font-size: 20px;
  font-weight: bold;
  color: ${props => (props.dark ? 'white' : '#414042')};
  z-index: 1;
`;

export const ChartContainer = styled(AreaChart).attrs({})`
  svg:not(:root) {
    ${roundedCorners}
  }
`;

const rateColors = (type: string): string => {
  if (type === 'positive') return '#58c51b';
  if (type === 'negative') return '#DB3418';
  return '#a1a1a1';
};

export const Rate = styled.span<{ type: string }>`
  font-size: 12px;
  color: ${props => rateColors(props.type)};
`;

export const Label = styled.span<{ dark: boolean }>`
  display: block;
  font-size: 10px;
  color: ${props => (props.dark ? 'white' : '#a9b1c4')};
`;

export const CustomTooltipContent = styled.div`
  ${roundedCorners}
  ${defaultBorder};
  background: #fff;
  padding: 4px;
  display: flex;
  align-items: center;
  height: 29px;
  p {
    margin: 0;
  }
`;

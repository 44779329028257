/* eslint-disable no-underscore-dangle */
import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react';

import api from 'services/crawlerApi';
import { CampaignType } from 'types';
import { useAuth } from './auth';

interface ContextProps {
  loading: boolean;
  campaigns: CampaignType[];
  loadCampaigns: () => void;
  createCampaign: (campaign: {
    name: string;
    description: string;
    influencers_favorites_ids: string[];
  }) => Promise<CampaignType>;
  updateCampaign: (
    campaignId: string,
    campaign: {
      name: string;
      description: string;
      influencers_favorites_ids: string[];
    },
  ) => Promise<CampaignType>;
  deleteCampaign: (campaignId: string) => Promise<CampaignType>;
}

const CampaignsContext = createContext<ContextProps>({} as ContextProps);

const CampaignsProvider: React.FC = ({ children }) => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([] as CampaignType[]);
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${token}`,
    }),
    [token],
  );

const loadCampaigns = useCallback(() => {
    setLoading(true);
    api
      .get('/campaigns', { headers })
      .then(res => {
        setCampaigns(res.data);
      })
      .catch(() => {
        setLoading(false)
        setCampaigns([])
      })
      .finally(() => setLoading(false));
  }, [headers]);

  const createCampaign = useCallback(
    (campaign: {
      name: string;
      description: string;
      influencers_favorites_ids: string[];
    }): Promise<CampaignType> =>
      new Promise((resolve, reject) => {
        api
          .post('/campaigns', campaign, {
            headers,
          })
          .then(res => {
            setCampaigns(prevCampaigns => [...prevCampaigns, res.data]);
            resolve(res.data);
          })
          .catch(() => reject());
      }),
    [headers],
  );

  const updateCampaign = useCallback(
    (
      campaignId: string,
      campaign: {
        name: string;
        description: string;
        influencers_favorites_ids: string[];
      },
    ): Promise<CampaignType> =>
      new Promise((resolve, reject) => {
        api
          .put(`/campaigns/${campaignId}`, campaign, {
            headers,
          })
          .then(res => {
            setCampaigns(prevCampagins =>
              prevCampagins.map(item =>
                item._id === campaignId ? res.data : item,
              ),
            );
            resolve(res.data);
          })
          .catch(() => reject());
      }),
    [headers],
  );

  const deleteCampaign = useCallback(
    (campaignId: string): Promise<CampaignType> =>
      new Promise((resolve, reject) => {
        api
          .delete(`/campaigns/${campaignId}`, {
            data: { campaign_id: campaignId },
            headers,
          })
          .then(res => {
            setCampaigns(prevCampaigns =>
              prevCampaigns.filter(item => item._id !== campaignId),
            );
            resolve(res.data);
          })
          .catch(() => reject());
      }),
    [headers],
  );

  return (
    <CampaignsContext.Provider
      value={{
        loading,
        loadCampaigns,
        campaigns,
        createCampaign,
        updateCampaign,
        deleteCampaign,
      }}
    >
      {children}
    </CampaignsContext.Provider>
  );
};

export function useCampaigns(): ContextProps {
  const context = useContext(CampaignsContext);
  if (!context) {
    throw new Error('useCampaigns must be used within an CampaignsProvider!');
  }
  return context;
}

export default CampaignsProvider;

/* eslint-disable consistent-return */
import React, { useMemo, useState, useEffect, useCallback } from 'react';

import { useIntl } from 'react-intl';

import InfluencerContainer from 'components/InfluencerContainer';
import { useInfluencers } from 'hooks/influencers';
import { useAuth } from 'hooks/auth';

import { useHistory, useLocation } from 'react-router-dom';
import { Container, ProfilesFound, TabContainer } from './styles';

import PriorityInfluencerContainer from 'components/PriorityInfluencerContainer';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const Home: React.FC = () => {
  const intl = useIntl();
  const {
    hasMore,
    searchInfluencers,
    profilesFound,
    loading,
  } = useInfluencers();
  const { user } = useAuth();
  const [activeKey, setActiveKey] = useState('1');
  const { pathname } = useLocation();
  const { push } = useHistory();

  const guest = useMemo(
    () => !user?.consumer_type || user?.consumer_type === 'unassigned_costumer',
    [user],
  );

  /* Sets the active tab key to 1 when influencers are searched */

  /*
  useEffect(() => {
    if (pathname === '/bbb23' || pathname === 'bbb') {
      return setActiveKey('2');
    }

    if (pathname === '/' || loading) {
      return setActiveKey('1');
    }
  }, [loading, pathname]);

  const handleTabChange = useCallback(
    key => (key === '1' ? push('/') : push('bbb23')),
    [push],
  ); */

  return (
    <>
      <Container hasMore={!loading && hasMore && !guest} loadMore={() => searchInfluencers()}>
        {profilesFound > 0 && (
          <ProfilesFound>
            <strong>
              {new Intl.NumberFormat('pt-BR').format(profilesFound)}
            </strong>
            <span>{intl.formatMessage({ id: 'home.profiles-found' })}</span>
          </ProfilesFound>
        )}
        <InfluencerContainer key="influencer-container" />
      </Container>
    </>
  );
};

export default Home;

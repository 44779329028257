import { useState, useEffect, useCallback } from 'react';
import { CLDR, CLDRFramework } from '@phensley/cldr';
import pkg from '../../package.json';

export default function useCldrTranslator(
  locale: string,
): (lang: string) => string {
  const [cldr, setCldr] = useState<CLDR>({} as CLDR);

  useEffect(() => {
    const version = pkg.dependencies['@phensley/cldr'];
    const packurl = `https://cdn.jsdelivr.net/npm/@phensley/cldr@${version}/packs`;

    const asyncLoader = (language: string): Promise<any> =>
      fetch(`${packurl}/${language}.json`)
        .then(r => r.json())
        .catch(err => console.log(err));

    const framework = new CLDRFramework({
      asyncLoader,
      packCacheSize: 8,
    });
    const { resolveLocale } = CLDRFramework;

    framework
      .getAsync(resolveLocale(locale).tag.expanded())
      .then((new_cldr: CLDR) => {
        setCldr(new_cldr);
      });
  }, [locale]);

  const translateLanguage = useCallback(
    lang => {
      const tag = cldr.General?.parseLanguageTag(lang);
      return cldr.General?.getLanguageDisplayName(tag);
    },
    [cldr.General],
  );

  return translateLanguage;
}

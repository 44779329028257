import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';

import Modal, { ModalProps } from 'antd/lib/modal';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import {
  Button,
  Form,
  Select,
  Radio,
  Input,
  Divider,
  Avatar,
  message,
  DatePicker,
} from 'antd';

import { useCurrentInfluencer } from 'hooks/currentInfluencer';
import { InfluencerType } from 'types';
import { useParams } from 'react-router-dom';
import countries from 'countries/countries';

import i18Countries from 'i18n-iso-countries';
import { useAuth } from 'hooks/auth';
import { SelectFieldInsert } from './styles';

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-var-requires
i18Countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
i18Countries.registerLocale(require('i18n-iso-countries/langs/pt.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
i18Countries.registerLocale(require('i18n-iso-countries/langs/es.json'));

const EditModal: React.FC<ModalProps> = ({
  visible,
  onCancel,
  onOk,
  ...rest
}) => {
  const intl = useIntl();
  const { user } = useAuth();

  const { id } = useParams();
  const [form] = Form.useForm();
  const { currentInfluencer, updateInfluencer } = useCurrentInfluencer();
  const [interestOptions, setInterestOptions] = useState([] as string[]);
  const [photoOptions, setPhotoOptions] = useState([] as string[]);
  const [newPhotoUrl, setNewPhotoUrl] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    const photoList = currentInfluencer.social.map(item => item.avatar);
    setPhotoOptions(photoList);
    setInterestOptions(currentInfluencer.interests);
  }, [currentInfluencer]);

  const onSubmitSuccess = useCallback(
    (e): void => {
      setLoadingSubmit(false);
      message.success(
        intl.formatMessage({
          id: 'profile.header.modal.edit.success',
        }),
        2,
      );
      if (onOk) onOk(e);
    },
    [intl, onOk],
  );

  const onSubmitFail = useCallback(() => {
    message.error(
      intl.formatMessage({
        id: 'profile.header.modal.edit.error',
      }),
      2,
    );
    setLoadingSubmit(false);
  }, [intl]);

  const handleSubmit = useCallback(
    e => {
      setLoadingSubmit(true);
      form.validateFields().then(values => {
        updateInfluencer(id, {
          ...values,
          gender: { type: values.gender },
        } as InfluencerType)
          .then(() => {
            onSubmitSuccess(e);
          })
          .catch(() => {
            onSubmitFail();
          });
      });
    },
    [form, id, updateInfluencer, onSubmitFail, onSubmitSuccess],
  );

  const handleCancel = useCallback(
    e => {
      form.setFieldsValue({
        ...currentInfluencer,
        gender: currentInfluencer.gender?.type,
        birthday: moment(currentInfluencer.birthday),
      });
      if (onCancel) onCancel(e);
    },
    [form, currentInfluencer, onCancel],
  );

  const onInputPhotoChange = (event: any): void => {
    setNewPhotoUrl(event.target.value);
  };

  const addNewPhoto = (): void => {
    setPhotoOptions([...photoOptions, newPhotoUrl]);
    setNewPhotoUrl('');
  };

  return (
    <Modal
      destroyOnClose
      {...rest}
      cancelText={intl.formatMessage({
        id: 'profile.header.modal.edit.cancel',
      })}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleSubmit}
      title={intl.formatMessage({
        id: 'profile.header.modal.edit.title',
      })}
      confirmLoading={loadingSubmit}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...currentInfluencer,
          gender: currentInfluencer.gender?.type,
          birthday: moment(currentInfluencer.birthday),
        }}
      >
        <Form.Item
          label={intl.formatMessage({
            id: 'profile.header.modal.edit.name',
          })}
          name="name"
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'menu.searchbar.filter.error.check-one-option',
              }),
            },
          ]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'profile.header.modal.edit.name.placeholder',
            })}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'profile.header.gender' })}
          name="gender"
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'menu.searchbar.filter.error.check-one-option',
              }),
            },
          ]}
        >
          <Radio.Group>
            <Radio value="M">
              {intl.formatMessage({ id: 'profile.header.modal.edit.male' })}
            </Radio>
            <Radio value="F">
              {intl.formatMessage({ id: 'profile.header.modal.edit.female' })}
            </Radio>
            <Radio value="ORG">
              {intl.formatMessage({
                id: 'profile.header.modal.edit.organization',
              })}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'profile.header.modal.edit.bio' })}
          name="wikipedia_description"
        >
          <Input.TextArea
            placeholder={intl.formatMessage({
              id: 'profile.header.modal.edit.bio.placeholder',
            })}
          />
        </Form.Item>
        <Form.Item label="Birth date" name="birthday">
          <DatePicker
            placeholder="Select the birth date"
            format="DD/MM/YYYY"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'profile.header.languages' })}
          name="languages"
        >
          <Select
            mode="multiple"
            placeholder={intl.formatMessage({
              id: 'profile.header.modal.edit.languages.placeholder',
            })}
          >
            <Option value="en">
              {intl.formatMessage({ id: 'general.language.en' })}
            </Option>
            <Option value="pt">
              {intl.formatMessage({ id: 'general.language.pt' })}
            </Option>
            <Option value="es">
              {intl.formatMessage({ id: 'general.language.es' })}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'profile.header.interests' })}
          name="interests"
        >
          <SelectField
            initialOptions={interestOptions || []}
            onChange={setInterestOptions}
            placeholder={intl.formatMessage({
              id: 'profile.header.modal.edit.interests.placeholder',
            })}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'profile.header.modal.edit.countries',
          })}
          name="countries"
        >
          <Select
            mode="multiple"
            placeholder={intl.formatMessage({
              id: 'profile.header.modal.edit.countries.placeholder',
            })}
            filterOption={(input, option) =>
              option?.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {Object.values(countries).map(country => {
              const language = user?.language || 'en';
              const code = i18Countries.getAlpha2Code(country.name, 'en');
              return (
                <Option value={`${code}`}>
                  {`${i18Countries.getName(code, language)} ${country.emoji}`}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'profile.header.modal.edit.photo' })}
          name="photo"
        >
          <Radio.Group>
            {photoOptions.map(item => (
              <Radio value={item} style={{ marginTop: 10 }} key={item}>
                <Avatar shape="square" size={64} src={item} />
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Input
          addonAfter={<PlusOutlined onClick={addNewPhoto} />}
          placeholder={intl.formatMessage({
            id: 'profile.header.modal.edit.photo.placeholder',
          })}
          onChange={onInputPhotoChange}
          value={newPhotoUrl}
        />
      </Form>
    </Modal>
  );
};

interface SelectFieldProps {
  onChange: (e: string[]) => void;
  initialOptions: string[];
  placeholder: string;
}

const SelectField: React.FC<SelectFieldProps> = ({
  initialOptions,
  onChange,
  placeholder,
}) => {
  const intl = useIntl();
  const [newOption, setNewOption] = useState('');
  const [options, setOptions] = useState(initialOptions);

  const onInputChange = (event: any): void => {
    setNewOption(event.target.value);
  };

  const addNewOption = (): void => {
    setOptions([...options, newOption]);
    setNewOption('');
  };

  return (
    <Select
      onChange={e => onChange(e)}
      mode="multiple"
      placeholder={placeholder}
      defaultValue={initialOptions}
      dropdownRender={menu => (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <SelectFieldInsert>
            <Input
              style={{ flex: 'auto' }}
              value={newOption}
              onChange={e => onInputChange(e)}
              placeholder={intl.formatMessage({
                id: 'profile.header.modal.edit.add-item.placeholder',
              })}
            />
            <Button onClick={() => addNewOption()}>
              <PlusOutlined /> Add item
            </Button>
          </SelectFieldInsert>
        </div>
      )}
    >
      {options.map(item => (
        <Option value={item}>{item}</Option>
      ))}
    </Select>
  );
};

export default EditModal;

import React from 'react';

import { List, Avatar } from 'antd';

import { TopType } from 'types';
import { Container, ItemMeta } from './styles';

interface RankingListProps {
  data: TopType[];
}

const RankingList: React.FC<RankingListProps> = ({ data }) => {
  return (
    <Container>
      {data.length > 0 && (
        <List
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item>
              <ItemMeta
                avatar={<Avatar>{index + 1}</Avatar>}
                title={item.name}
              />
              <span>{item.amount}</span>
            </List.Item>
          )}
        />
      )}
    </Container>
  );
};

export default RankingList;

import React from 'react';

import Logo from 'assets/images/influencers-logo.png';
import { Container, Image } from './styles';
import { Link } from 'react-router-dom';


const Header: React.FC = () => {
  return (
    <Container>
      <Link to='/'>
        <Image src={Logo}/>
      </Link>
    </Container>
  );
};

export default Header;

import React, { useMemo } from 'react';
import {
  CaretUpFilled,
  CaretDownFilled,
  LineOutlined,
} from '@ant-design/icons';
import {
  Area,
  ResponsiveContainer,
  Tooltip as TooltipRecharts,
} from 'recharts';
import { uuid } from 'uuidv4';

import formatNumber from 'utils/formatNumber';
import fixNumberDigits from 'utils/fixNumberDigits';

import { EvolutionType } from 'types';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import {
  Container,
  ChartContainer,
  HeaderContainer,
  Statistic,
  Rate,
  Label,
  CustomTooltipContent,
} from './styles';

interface EvolutionChartProps {
  data: EvolutionType[];
  dataKey: string;
  label?: string;
  colorA?: string;
  colorB?: string;
  backgroundColor?: string;
  dark?: boolean;
}

const EvolutionChart: React.FC<EvolutionChartProps> = ({
  data,
  dataKey,
  label = '',
  colorA = 'blue',
  colorB = 'blue',
  backgroundColor = 'transparent',
  dark = false,
}) => {
  const formattedData = useMemo(
    () =>
      [...data].sort((a, b) => {
        return +new Date(b.date) - +new Date(a.date);
      }),
    [data],
  );

  return (
    <Container backgroundColor={backgroundColor}>
      <Header label={label} dark={dark} data={formattedData} />
      <Chart
        data={formattedData}
        dataKey={dataKey}
        colorA={colorA}
        colorB={colorB}
      />
    </Container>
  );
};

interface HeaderProps {
  label: string;
  dark: boolean;
  data: EvolutionType[];
}

const Header: React.FC<HeaderProps> = ({ label, dark, data }) => {
  const intl = useIntl();
  const rate = useMemo(
    () =>
      data[0]?.amount && data[1]?.amount
        ? fixNumberDigits(
            ((data[0].amount - data[1].amount) / data[1].amount) * 100,
          )
        : 0,
    [data],
  );

  const type = useMemo(() => {
    if (rate > 0) {
      return 'positive';
    }
    if (rate < 0) {
      return 'negative';
    }
    return 'neutral';
  }, [rate]);

  const averageValue = useMemo(() => {
    const average =
      data.reduce((sum, { amount }: { amount: number }) => sum + amount, 0) /
      data.length;
    return formatNumber(fixNumberDigits(average));
  }, [data]);

  const rateTooltipTitle = useMemo(() => {
    if (rate === 0)
      return intl.formatMessage({
        id: 'profile.social.charts.evolution.rate-no-variation',
      });
    const variationIntl = {
      positive: intl.formatMessage({
        id: 'profile.social.charts.evolution.rate-increase-of',
      }),
      negative: intl.formatMessage({
        id: 'profile.social.charts.evolution.rate-decrease-of',
      }),
      neutral: intl.formatMessage({
        id: 'profile.social.charts.evolution.rate-increase-of',
      }),
    };
    const fromIntl = intl.formatMessage({
      id: 'profile.social.charts.evolution.rate-from',
    });
    const toIntl = intl.formatMessage({
      id: 'profile.social.charts.evolution.rate-to',
    });
    const fromDate = new Date(
      data[1]?.date.concat('T00:00-0800') || '',
    ).toLocaleDateString(intl.formatMessage({ id: 'general.locale' }));
    const toDate = new Date(
      data[0]?.date.concat('T00:00-0800') || '',
    ).toLocaleDateString(intl.formatMessage({ id: 'general.locale' }));
    const variationNumber = formatNumber(
      Math.abs(fixNumberDigits(data[0]?.amount - data[1]?.amount) || 0),
    );

    return `${variationIntl[type]} ${variationNumber} ${fromIntl}
  ${fromDate} ${toIntl} ${toDate}`;
  }, [data, intl, rate, type]);

  return (
    <HeaderContainer>
      <div>
        <Label dark={dark}>{label}</Label>
      </div>
      <Tooltip
        title={intl.formatMessage({
          id: 'profile.social.charts.info.evolution-mean',
        })}
      >
        <Statistic dark={dark}>{averageValue}</Statistic>
      </Tooltip>
    </HeaderContainer>
  );
};

interface ChartProps {
  data: EvolutionType[];
  dataKey: string;
  colorA: string;
  colorB: string;
}
const Chart: React.FC<ChartProps> = ({ data, dataKey, colorA, colorB }) => {
  const gradientId = `gradient-${uuid()}`;
  const formattedDate = useMemo(() => [...data].reverse(), [data]);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ChartContainer
        data={formattedDate}
        margin={{ top: 15, right: 0, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id={gradientId} gradientTransform="rotate(0)">
            <stop offset="5%" stopColor={colorA} />
            <stop offset="95%" stopColor={colorB} />
          </linearGradient>
        </defs>
        <TooltipRecharts content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey={dataKey}
          fillOpacity={1}
          stroke="transparent"
          fill={`url(#${gradientId})`}
        />
      </ChartContainer>
    </ResponsiveContainer>
  );
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: { payload: EvolutionType }[];
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active = false,
  payload = [],
}) => {
  const intl = useIntl();
  const valueFormatted = useMemo(() => {
    const value = (payload && payload[0] && payload[0].payload.amount) || 0;
    return formatNumber(fixNumberDigits(value));
  }, [payload]);

  const formattedDate = useMemo(
    () =>
      new Date(
        payload[0]?.payload.date.concat('T00:00-0800') || '',
      ).toLocaleDateString(intl.formatMessage({ id: 'general.locale' })),
    [intl, payload],
  );

  return (
    <>
      {active && (
        <CustomTooltipContent>
          <p>{`${formattedDate} : ${valueFormatted}`}</p>
        </CustomTooltipContent>
      )}
    </>
  );
};

export default EvolutionChart;

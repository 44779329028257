import InfiniteScroll from 'react-infinite-scroller';

import styled from 'styled-components';
import { Tabs } from 'antd';

export const Container = styled(InfiniteScroll)`
  width: 100%;
  padding: 0px 0px 32px;
  @media (max-width: 1080px) {
    /*padding: 58px 24px 24px;*/
    padding: 0px 24px 24px;
  }
`;

export const ProfilesFound = styled.div`
  padding: 1.2rem 0;
  text-align: right;
  color: #666;
  font-size: 0.95rem;
  strong {
    margin-right: 8px;
  }
`;

export const TabContainer = styled(Tabs)`
  width: 100%;
  .ant-tabs-nav,
  .ant-tabs-nav-wrap,
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-tab {
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 0;
  }
  .ant-tabs-nav {
    margin-top: 20px;
    margin-bottom: 20px;
    &::before {
      border-bottom-color: #e2e2e2;
    }
  }
  @media (max-width: 1080px) {
    .ant-tabs-nav {
      margin: 68px 0px 20px;
    }
  }
  .ant-tabs-tab,
  .ant-tabs-tab:hover,
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
    color: inherit;
  }
  .ant-tabs-ink-bar {
    background: #fdac47;
  }
`;

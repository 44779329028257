import styled from 'styled-components';
import { List } from 'antd';

export const Container = styled.div`
  overflow: auto;
  padding-right: 10px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    background: ${props => props.theme.colors.tertiaryLight};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: ${props => props.theme.colors.primaryLight};
  }
`;

export const ItemMeta = styled(List.Item.Meta)`
  overflow: hidden;
  margin-right: 10px;
`;

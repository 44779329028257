/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useMemo } from 'react';
import { message } from 'antd';
import { useIntl } from 'react-intl';
import { useFavorites } from 'hooks/favorites';
import { useAuth } from 'hooks/auth';
import ActiveCampaign from 'components/Modals/ActiveCampaign';
import {
  Container,
  HeartIconOutlined,
  HeartIconFilled,
  BookMarkIconContainer,
  FavoriteButtonContainer,
} from './styles';

interface InfluencerFavoriteProps {
  influencerId: string;
}

const InfluencerFavoriteButton: React.FC<InfluencerFavoriteProps> = ({
  influencerId,
}) => {
  const intl = useIntl();
  const { user } = useAuth();
  const [checked, setChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { isFavorite, addFavorite, deleteFavorite } = useFavorites();

  const isGuest = useMemo(
    () => !user?.consumer_type || user?.consumer_type === 'unassigned_costumer',
    [user],
  );

  useEffect(() => {
    if (isFavorite(influencerId)) {
      setChecked(true);
    }
  }, [influencerId, isFavorite]);

  const handleClick = (event: React.MouseEvent): void => {
    event.stopPropagation();
    if (isGuest) {
      setShowModal(true);
    } else {
      setChecked(isChecked => !isChecked);
      if (checked) {
        deleteFavorite(influencerId).catch(() => {
          message.error(
            intl.formatMessage({
              id: 'favorites.button.delete.error',
            }),
          );
          setChecked(true);
        });
      } else {
        addFavorite(influencerId).catch(() => {
          message.error(
            intl.formatMessage({
              id: 'favorites.button.add.error',
            }),
          );
          setChecked(false);
        });
      }
    }
  };

  return (
    <>
      <Container onClick={handleClick} checked={checked}>
        <FavoriteButtonContainer>
          {checked ? <HeartIconFilled /> : <HeartIconOutlined />}
        </FavoriteButtonContainer>
        <BookMarkIconContainer />
      </Container>
      <ActiveCampaign
        visible={showModal}
        onCancel={e => {
          e.stopPropagation();
          setShowModal(false);
        }}
      />
    </>
  );
};

export default InfluencerFavoriteButton;

import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1080px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
`;

export const Loader = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

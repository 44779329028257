import styled from 'styled-components';

import { roundedCorners, defaultBorder } from 'styles/reuse';

export const Container = styled.div`
  display: grid;
  grid-gap: 16px;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

export const SmallChartsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
  }
`;

export const RankingChart = styled.div`
  ${roundedCorners}
  ${defaultBorder};
  padding: 12px;
  width: 100%;
  overflow: hidden;
  height: 320px;
  display: flex;
  flex-direction: column;
`;

export const WordCloudContainer = styled(RankingChart)`
  height: 360px;
`;

export const GraphChartContainer = styled(RankingChart)`
  height: 500px;
`;

export const Label = styled.label`
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  span {
    cursor: pointer;
    margin-left: auto;
    & + span {
      margin-left: 16px;
    }
  }
`;

export const NoData = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GraphOption = styled.span<{ active: boolean }>`
  font-size: '10px';
  fill: ${props => (props.active ? '#1890FF' : '#595959')};
  stroke: ${props => (props.active ? '#1890FF' : '#595959')};
`;

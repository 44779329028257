import { css } from 'styled-components';

export const roundedCorners = css`
  border-radius: ${props => props.theme.size.borderRadius};
`;

export const shadow = css`
  box-shadow: 0px 3px 6px rgba(65, 64, 66, 0.22);
`;

export const defaultBorder = css`
  border: 1px solid #e8e8e8;
`;

export const scrollBar = css`
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    background: ${props => props.theme.colors.tertiaryLight};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: ${props => props.theme.colors.primaryLight};
  }
`;

import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { FiltersType } from 'hooks/influencers';
import { ReactComponent as IconFacebook } from 'assets/icons/socials/icon-facebook.svg';
import IconInstagram from 'assets/icons/socials/icon-instagram.png';
import { ReactComponent as IconX } from 'assets/icons/socials/icon-x.svg';
import IconYoutube from 'assets/icons/socials/icon-youtube.png';

import { ReactComponent as IconFacebookDisabled } from 'assets/icons/socials/icon-facebook-disabled.svg';
import { ReactComponent as IconInstagramDisabled } from 'assets/icons/socials/icon-instagram-disabled.svg';
import { ReactComponent as IconXDisabled } from 'assets/icons/socials/icon-x-disable.svg';
import { ReactComponent as IconYoutubeDisabled } from 'assets/icons/socials/icon-youtube-disabled.svg';

import { Checkbox, Button, Form, Select } from 'antd';

import countries from 'countries/countries';
import i18Countries from 'i18n-iso-countries';
import { useAuth } from 'hooks/auth';
import {
  FilterSmallScreen,
  FilterLargeScreen,
  FormInputNumber,
  FollowersRange,
  SocialsCheckboxGroup,
  SelectLanguage,
  SecondFilter,
  Row,
  Field,
  CheckboxGroup,
  ThirdFilter,
} from './styles';
import SocialCheckbox from './SocialCheckbox';

// eslint-disable-next-line @typescript-eslint/no-var-requires
i18Countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
i18Countries.registerLocale(require('i18n-iso-countries/langs/pt.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
i18Countries.registerLocale(require('i18n-iso-countries/langs/es.json'));

const { Option } = Select;

interface FilterProps {
  initialFilters: FiltersType;
  handleFiltersChange: (newFilters: FiltersType) => void;
  isVisible: boolean;
  onOk: () => void;
}

const Filter: React.FC<FilterProps> = ({
  initialFilters,
  handleFiltersChange,
  isVisible,
  onOk,
}) => {
  const { user } = useAuth();
  const intl = useIntl();
  const [form] = Form.useForm();

  const onFormValuesChange = useCallback(
    (newFilters: FiltersType): void => {
      handleFiltersChange(newFilters);
    },
    [handleFiltersChange],
  );

  return (
    <FilterContainer open={isVisible} onOk={onOk}>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialFilters}
        onValuesChange={values => onFormValuesChange(values as FiltersType)}
      >
        <Field
          style={{ display: 'block' }}
          name="whereToSearch"
          label={intl.formatMessage({ id: 'menu.searchbar.search-for' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'menu.searchbar.filter.error.check-one-option',
              }),
            },
          ]}
        >
          <CheckboxGroup>
            <Checkbox value="name">
              {intl.formatMessage({ id: 'menu.searchbar.filter.name' })}
            </Checkbox>
            <Checkbox value="username">
              {intl.formatMessage({
                id: 'menu.searchbar.filter.username',
              })}
            </Checkbox>
            <Checkbox value="bio">
              {intl.formatMessage({ id: 'menu.searchbar.filter.bio' })}
            </Checkbox>
            <Checkbox value="top_hashtags">
              {intl.formatMessage({
                id: 'menu.searchbar.filter.top-hashtags',
              })}
            </Checkbox>
            <Checkbox value="top_terms">
              {intl.formatMessage({
                id: 'menu.searchbar.filter.top-terms',
              })}
            </Checkbox>
            <Checkbox value="top_entities">
              {intl.formatMessage({
                id: 'menu.searchbar.filter.top-entities',
              })}
            </Checkbox>
            <Checkbox value="top_mentions">
              {intl.formatMessage({
                id: 'menu.searchbar.filter.top-mentions',
              })}
            </Checkbox>
          </CheckboxGroup>
        </Field>
        <SecondFilter>
          <Field
            label={intl.formatMessage({
              id: 'menu.searchbar.filter.social-networks',
            })}
            style={{ gridArea: 'social' }}
            name="socialNetworks"
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'menu.searchbar.filter.error.check-one-option',
                }),
              },
            ]}
          >
            <Checkbox.Group>
              <SocialsCheckboxGroup>
                <SocialCheckbox
                  value="facebook"
                  activeIcon={<IconFacebook />}
                  disabledIcon={<IconFacebookDisabled />}
                />
                <SocialCheckbox
                  value="instagram"
                  activeIcon={<img src={IconInstagram} alt="instagram" />}
                  disabledIcon={<IconInstagramDisabled />}
                />
                <SocialCheckbox
                  value="twitter"
                  activeIcon={<IconX />}
                  disabledIcon={<IconXDisabled />}
                />
                <SocialCheckbox
                  value="youtube"
                  activeIcon={<img src={IconYoutube} alt="youtube" />}
                  disabledIcon={<IconYoutubeDisabled />}
                />
              </SocialsCheckboxGroup>
            </Checkbox.Group>
          </Field>
          <Field
            label={intl.formatMessage({ id: 'menu.searchbar.filter.gender' })}
            name="gender"
            style={{ gridArea: 'profiletype' }}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'menu.searchbar.filter.error.check-one-option',
                }),
              },
            ]}
          >
            <Checkbox.Group>
              <Row>
                <Checkbox value="PER">
                  {intl.formatMessage({ id: 'menu.searchbar.filter.person' })}
                </Checkbox>
                <Checkbox value="ORG">
                  {intl.formatMessage({
                    id: 'menu.searchbar.filter.organization',
                  })}
                </Checkbox>
              </Row>
            </Checkbox.Group>
          </Field>
        </SecondFilter>

        <ThirdFilter>
          <Field
            label={intl.formatMessage({
              id: 'menu.searchbar.filter.followers',
            })}
            style={{ gridArea: 'followers', display: 'block' }}
            name="followers"
          >
            <Row>
              <FollowersRange
                name="min_followers"
                label="Min."
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value > getFieldValue('max_followers')) {
                        return Promise.reject(
                          new Error(
                            intl.formatMessage({
                              id: 'menu.searchbar.filter.error.min-followers',
                            }),
                          ),
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <FormInputNumber />
              </FollowersRange>
              <FollowersRange
                name="max_followers"
                label="Max."
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value <= getFieldValue('min_followers')) {
                        return Promise.reject(
                          new Error(
                            intl.formatMessage({
                              id: 'menu.searchbar.filter.error.max-followers',
                            }),
                          ),
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <FormInputNumber />
              </FollowersRange>
            </Row>
          </Field>
          <Field
            label={intl.formatMessage({ id: 'menu.searchbar.filter.age' })}
            style={{ gridArea: 'age' }}
            name="age"
          >
            <Select>
              <Option value="[]">
                {intl.formatMessage({ id: 'menu.searchbar.filter.all-age' })}
              </Option>
              <Option value="[0,18]">0 - 18</Option>
              <Option value="[19,24]">19 - 24</Option>
              <Option value="[25,35]">25 - 35</Option>
              <Option value="[35,45]">35 - 45</Option>
              <Option value="[45,200]">45 - 55+</Option>
            </Select>
          </Field>
          <Field
            label={intl.formatMessage({
              id: 'menu.searchbar.filter.languages',
            })}
            style={{ gridArea: 'languages' }}
            name="languages"
          >
            <SelectLanguage
              mode="multiple"
              placeholder={intl.formatMessage({
                id: 'menu.searchbar.filter.languages.placeholder',
              })}
            >
              <Option value="en">
                {intl.formatMessage({ id: 'general.language.en' })}
              </Option>
              <Option value="pt">
                {intl.formatMessage({ id: 'general.language.pt' })}
              </Option>
              <Option value="es">
                {intl.formatMessage({ id: 'general.language.es' })}
              </Option>
            </SelectLanguage>
          </Field>
          <Field
            label={intl.formatMessage({
              id: 'menu.searchbar.filter.countries',
            })}
            name="countries"
            style={{ gridArea: 'countries' }}
          >
            <Select
              mode="multiple"
              style={{
                width: '190px',
                maxHeight: '120px',
                overflowY: 'scroll'
              }}
              placeholder={intl.formatMessage({
                id: 'menu.searchbar.filter.countries.placeholder',
              })}
              filterOption={(input, option) =>
                option?.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {Object.values(countries).map(country => {
                let language;

                if (user?.language?.toString() == 'pt_PT') {
                  language = 'pt';
                } else if (user?.language?.toString() == 'pt_BR') {
                  language = 'pt';
                } else {
                  language = user?.language || 'en';
                }

                const code = i18Countries.getAlpha2Code(country.name, 'en');
                return (
                  <Option value={`${code}`}>
                    {`${i18Countries.getName(code, language)} ${country.emoji}`}
                  </Option>
                );
              })}
            </Select>
          </Field>
        </ThirdFilter>
      </Form>
    </FilterContainer>
  );
};

interface FilterContainerProps {
  open: boolean;
  onOk: () => void;
}

const FilterContainer: React.FC<FilterContainerProps> = ({
  open,
  onOk,
  children,
}) => {
  const isClientLarge =
    document.getElementsByTagName('body')[0].clientWidth >= 1080;
  return isClientLarge ? (
    <FilterLargeScreen open={open}>{children}</FilterLargeScreen>
  ) : (
    <FilterSmallScreen
      open={open}
      onOk={onOk}
      onCancel={onOk}
      footer={[
        <Button key="submit" type="primary" onClick={onOk}>
          Ok
        </Button>,
      ]}
    >
      {children}
    </FilterSmallScreen>
  );
};

export default Filter;

import styled from 'styled-components';
import { Card, Button } from 'antd';
import { roundedCorners, defaultBorder } from 'styles/reuse';

export const Container = styled(Card)`
  ${roundedCorners};
  ${defaultBorder};
  height: fit-content;
  width: 100%;
  max-width: 100%;
`;

export const DownloadButton = styled(Button)`
  border: 0;
  width: 60px;
  box-shadow: none;
  display: flex;
  align-content: center;
  padding: 10;
  justify-content: center;
  .anticon {
    font-size: 20px;
    line-height: 0px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

/* eslint-disable no-extra-boolean-cast */
import { Divider } from 'antd';
import styled from 'styled-components';
import BrokenLink from 'assets/images/broken-link.png';

import { roundedCorners } from 'styles/reuse';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin-top: 16px;
  }
`;

export const LastPostsTitle = styled.h6`
  margin-bottom: 5px;
`;

export const TitleDivider = styled(Divider)`
  margin: 0;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ToPost = styled.a``;

export const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: white;
  color: black;

  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid black;
  border-radius: 15px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const PostContainer = styled.div`
  ${roundedCorners}
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  width: inherit;
  overflow: hidden;
  border: 1px solid transparent;
  overflow: hidden;
  position: relative;
`;

export const PostVideo = styled.video`
  ${roundedCorners}
  height: 222px;
  width: 100%;
  object-fit: cover;
  @media (max-width: 1200px) {
    height: 250px;
  }
`;

export const PostImage = styled.div<{ url: string; full?: boolean }>`
  ${roundedCorners}
  overflow: hidden;
  width: 100%;
  height: ${props => (!!props.full ? '416px' : '222px')};
  background-position: center center;
  background-size: cover !important;
  background-image: url(${props => props.url || BrokenLink}), url(${BrokenLink});
  @media (max-width: 1200px) {
    height: 250px;
  }
`;

export const PostContent = styled.div<{ hasContent: boolean }>`
  margin: 16px 0 8px 0;

  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(1fr);
  strong {
    font-size: 1rem;
    line-height: 1.1rem;
    height: 2.2rem;
    overflow: hidden;
    margin-bottom: 8px;
  }
  span {
    color: #999;
    font-size: 0.8rem;
    margin-bottom: 8px;
    text-align: center;
  }
  p {
    line-height: 1.05rem;
    font-size: 0.9rem;
    height: 3.15rem;
    overflow: hidden;
  }
`;

export const PostMetrics = styled.div`
  color: ${props => props.theme.colors.tertiary};
`;

export const Metric = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.tertiary};
  color: #666;
  p {
    color: #666;
    margin: 6px 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    i {
      margin-right: 8px;
    }
  }
`;

export default function formatNumber(number: number): string {
  if (number / 1000 < 1) {
    return number.toString();
  }

  if (number / 1000 >= 1 && number / 1000 < 1000) {
    return `${(number / 1000).toFixed(1)} K`;
  }

  if (number / 1000000 >= 1 && number / 1000000 < 1000) {
    return `${(number / 1000000).toFixed(1)} M`;
  }
  if (number / 1000000000 >= 1) {
    return `${(number / 1000000000).toFixed(1)} B`;
  }
  return number.toString();
}

import ReactWordcloud from 'react-wordcloud';

import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;

  > div {
    height: 100%;
  }
`;

export const Wordcloud = styled(ReactWordcloud).attrs(props => {
  const regular = props.theme.colors.secondary;
  const dark = props.theme.colors.secondaryDark;
  const darker = props.theme.colors.secondaryDarker;

  return {
    options: {
      colors: [regular, dark, darker],
      enableTooltip: true,
      deterministic: true,
      fontFamily: 'Lato, "sans serif"',
      fontSizes: [21, 65],
      padding: 1,
      rotations: 0,
      rotationAngles: [0, -90],
      spiral: 'archimedean',
      transitionDuration: 1000,
    },
  };
})``;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 24px 0px;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1.3fr 2fr;
  @media (max-width: 1080px) {
    padding: 76px 24px 24px;
    grid-template-columns: 1fr;
  }
  @media (max-width: 400px) {
    padding: 58px 8px 8px;
    grid-gap: 16px;
    grid-template-columns: 1fr;
  }
`;

import styled from 'styled-components';
import { Collapse, Typography, Button } from 'antd';
import { roundedCorners, defaultBorder } from 'styles/reuse';
import { scrollBar } from 'styles/reuse';

const { Panel } = Collapse;
const { Title } = Typography;

export const InfluencersListContainer = styled.div`
  ${scrollBar};
  margin-top: 15px;
  overflow: auto;
  padding: 8px 15px 8px 0px;
  max-height: 300px;
`;

export const PanelTitle = styled(Title).attrs({
  level: 3,
})`
  display: inline-block;
  margin: 0 !important;
`;

export const CampaignCollapse = styled(Collapse).attrs({})`
  border: none;
  background-color: transparent;
  .ant-collapse-item {
    ${defaultBorder};
    ${roundedCorners};
    background-color: #ffffff;
    margin-bottom: 18px;

    &:last-child {
      ${roundedCorners};
    }
  }
  .ant-collapse-item > .ant-collapse-header {
    padding: 16px 16px 16px 40px;
    > .anticon {
      left: 16px;
    }
  }
  .ant-collapse-item > .ant-collapse-content {
    margin: 0px 24px 8px 24px;
  }
`;

export const CampaignPanel = styled(Panel)``;

export const DescriptionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MenuButton = styled(Button)`
  border: 0;
  padding: 10px;
  box-shadow: none;
  .anticon {
    font-size: 18px;
  }
`;

import styled from 'styled-components';
import { scrollBar } from 'styles/reuse';

export const InfiniteScrollContainer = styled.div`
  ${scrollBar};
  border: 1px solid transparent;
  margin-top: 15px;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 15px 8px 0px;
  max-height: 300px;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  bottom: 90px;
  left: 0;
  width: 100%;
  text-align: center;
`;

export const ProfilesSelected = styled.div`
  max-height: 300px;
  overflow: auto;
`;

import React, { useMemo, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { Button, Modal } from 'antd';

import { uuid } from 'uuidv4';
import {
  MessageOutlined,
  LikeOutlined,
  ShareAltOutlined,
  SmileOutlined,
  EyeOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import { useCurrentInfluencer } from 'hooks/currentInfluencer';
import formatNumber from 'utils/formatNumber';
import { Typography } from 'antd';
import { PostType } from 'types';
import { useAuth } from 'hooks/auth';
import { parseISO, format } from 'date-fns';
import ActiveCampaign from 'components/Modals/ActiveCampaign';

import { useModals } from 'hooks/modals';
import AppProvider from 'hooks';
import {
  Container,
  PostContainer,
  PostContent,
  PostMetrics,
  Metric,
  ToPost,
  Wrapper,
  LastPostsTitle,
  TitleDivider,
  PostVideo,
  PostImage,
} from './styles';

const { Paragraph } = Typography;

const LastPosts: React.FC = () => {
  const intl = useIntl();
  const { currentSocial, currentInfluencer } = useCurrentInfluencer();
  const { last_posts_content: posts } = currentInfluencer.social[currentSocial];
  const currentSocialUsername = currentInfluencer.social[currentSocial].username;

  const [socialMediaUsername, setSocialMediaUsername] = useState(currentSocialUsername);
  const [exposedPosts, setExposedPosts] = useState(posts?.slice(0, 3) || []);
  const [postsPage, setPostsPage] = useState(1);
  const [modal, setModal] = useState(false);

  const { user } = useAuth();
  const { consumer_type } = user;

  const guest = useMemo(
    () =>
      !(consumer_type === 'unassigned_costumer' || consumer_type === undefined),
    [consumer_type],
  );

  const handleClick = useCallback(() => {
    if (guest) {
      setExposedPosts([
        ...exposedPosts,
        ...posts.slice(3 * postsPage, 3 * postsPage + 3),
      ]);
      setPostsPage(postsPage + 1);
    } else {
      setModal(true);
    }
  }, [exposedPosts, guest, posts, postsPage]);

  if (!posts) return <></>;

  return (
    <div>
      <LastPostsTitle>
        {intl.formatMessage({ id: 'profile.social.last-posts' })}
      </LastPostsTitle>
      <TitleDivider />
      <Wrapper>
        <Container> 
          {exposedPosts.map(post_content => (
            <Post data={{...post_content, authorId: socialMediaUsername}} key={uuid()} />
          ))}
        </Container>
        {posts.length !== exposedPosts.length && (
          <Button shape="round" type="primary" onClick={handleClick}>
            {intl.formatMessage({ id: 'profile.social.last-posts.see-more' })}
          </Button>
        )}
        <ActiveCampaign visible={modal} onCancel={() => setModal(false)} />
      </Wrapper>
    </div>
  );
};

interface PostProps {
  data: PostType;
}

const Post: React.FC<PostProps> = ({ data }) => {
  const intl = useIntl();
  const { user } = useAuth();
  const { openActiveCampaignModal } = useModals();
  const {
    content,
    picture,
    picture_searchable,
    created_at,
    title,
    icon,
    description,
    service,
    type,
  } = data;

  const link = useMemo(() => {
    const {
      link, 
      id, 
      authorId
    } = data;

    switch (service){
      case 'instagram':
        return link; 
      case 'facebook':
        return link;
      case 'twitter':
        return "https://www.twitter.com/" + authorId + "/status/" + id.slice(20);
      case 'youtube':
        if (id) {
          return "https://www.youtube.com/watch?v=" + id.slice(20);
        }
    }
  }, [data])

  const metrics = useMemo(() => {
    const {
      dislikes_count,
      comments,
      likes,
      shares,
      view_count,
      reactions,
    } = data;
    switch (service) {
      case 'facebook': {
        return [
          {
            label: intl.formatMessage({
              id: 'profile.social.last-posts.comments',
            }),
            value: comments,
            icon: <MessageOutlined />,
          },
          {
            label: intl.formatMessage({
              id: 'profile.social.last-posts.likes',
            }),
            value: likes,
            icon: <LikeOutlined />,
          },
          {
            label: intl.formatMessage({
              id: 'profile.social.last-posts.reactions',
            }),
            value: reactions,
            icon: <SmileOutlined />,
          },
          {
            label: intl.formatMessage({
              id: 'profile.social.last-posts.shares',
            }),
            value: shares,
            icon: <ShareAltOutlined />,
          },
        ];
      }
      case 'instagram': {
        return [
          {
            label: intl.formatMessage({
              id: 'profile.social.last-posts.comments',
            }),
            value: Number(comments),
            icon: <MessageOutlined />,
          },

          {
            label: intl.formatMessage({
              id: 'profile.social.last-posts.likes',
            }),
            value: Number(likes),
            icon: <LikeOutlined />,
          },
        ];
      }
      case 'twitter': {
        return [
          {
            label: 'RTs',
            value: comments,
            icon: <RetweetOutlined />,
          },

          {
            label: intl.formatMessage({
              id: 'profile.social.last-posts.likes',
            }),
            value: Number(likes),
            icon: <LikeOutlined />,
          },
        ];
      }
      case 'youtube': {
        return [
          {
            label: intl.formatMessage({
              id: 'profile.social.last-posts.views',
            }),
            value: view_count,
            icon: <div></div>,
          },
          {
            label: intl.formatMessage({
              id: 'profile.social.last-posts.likes',
            }),
            value: likes,
            icon: <div></div>,
          },
          {
            label: intl.formatMessage({
              id: 'profile.social.last-posts.comments',
            }),
            value: comments,
            icon: <div></div>,
          },
        ];
      }
      default: {
        return [];
      }
    }
  }, [data, intl, service]);

  const formattedDate = useMemo(
    () => format(parseISO(created_at), 'HH:mm dd/MM/yyyy'),
    [created_at],
  );

  const postMedia = useCallback(
    (full?: boolean): React.ReactNode => {
      const src = picture || picture_searchable || icon;
      if (type === 'video' && service === 'instagram') {
        return (
          <PostVideo controls>
            <source src={src} type="video/mp4" />
          </PostVideo>
        );
      }
      return <PostImage url={src} full={full} />;
    },
    [icon, picture, picture_searchable, type, service],
  );

  const handleSeeFullPost = useCallback(() => {
    if (user.consumer_type === 'unassigned_costumer')
      return openActiveCampaignModal();
    return Modal.info({
      width: 480,
      centered: true,
      icon: <></>,
      content: (
        <AppProvider>
          <div style={{ display: 'grid' }}> 
            <ToPost href={link} target="_blank">
              {postMedia(true)}
              <PostContent hasContent={!!content || !!title}>
                <span>{formattedDate}</span>
                {service === 'youtube' && <Paragraph strong>{title}</Paragraph>}
                <Paragraph>{content || description}</Paragraph>
              </PostContent>
            </ToPost>
            <PostMetrics>
              {metrics.map(metric => (
                <MetricItem
                  label={metric.label}
                  value={metric.value}
                  icon={metric.icon}
                  key={uuid()}
                />
              ))}
            </PostMetrics>
          </div>
        </AppProvider>
      ),
    });
  }, [
    content,
    description,
    formattedDate,
    link,
    metrics,
    openActiveCampaignModal,
    postMedia,
    service,
    title,
    user.consumer_type,
  ]);

  return (
    <PostContainer>
      <ToPost href={link} target="_blank">
        {postMedia()}
        <PostContent hasContent={!!content || !!title}>
          <span>{formattedDate}</span>
          {service === 'youtube' && (
            <div style={{ height: '40px' }}>
              <Paragraph ellipsis={{ rows: 2, expandable: false }} strong>
                {title}
              </Paragraph>
            </div>
          )}
          <div style={{ height: '60px' }}>
            <Paragraph ellipsis={{ rows: 3, expandable: false }}>
              {content || description}
            </Paragraph>
          </div>
        </PostContent>
      </ToPost>
      <Button type="text" onClick={handleSeeFullPost} style={{ marginTop: 0 }}>
        <strong>
          {intl.formatMessage({
            id: 'profile.social.last-posts.see-full-post',
          })}
        </strong>
      </Button>
      <PostMetrics>
        {metrics.map(metric => (
          <MetricItem
            label={metric.label}
            value={metric.value}
            icon={metric.icon}
            key={uuid()}
          />
        ))}
      </PostMetrics>
    </PostContainer>
  );
};

interface MetricItemProps {
  label: string;
  value: number;
  icon: React.ReactNode;
}

const MetricItem: React.FC<MetricItemProps> = ({ label, value, icon }) => {
  const formattedValue = useMemo(() => {
    return (value && formatNumber(value)) || '0';
  }, [value]);

  return (
    <Metric>
      <p>
        {icon}&nbsp;&nbsp;{label}
      </p>
      <strong>{formattedValue}</strong>
    </Metric>
  );
};

export default LastPosts;

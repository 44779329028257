import React, { useCallback, useMemo } from 'react';

import Modal, { ModalProps } from 'antd/lib/modal';
import api from 'services/crawlerApi';

import { message } from 'antd';
import { useCurrentInfluencer } from 'hooks/currentInfluencer';

const ForceUpdateModal: React.FC<ModalProps> = ({
  visible,
  onCancel,
  onOk,
  ...rest
}) => {
  const { currentInfluencer } = useCurrentInfluencer();

  const socialsFormatted = useMemo(
    () =>
      currentInfluencer.social.map(item => ({
        social_id: item.id,
        social_network: item.social_network,
      })),
    [currentInfluencer.social],
  );

  const handleSubmit = useCallback(
    e => {
      api
        .put(`/influencers/force_update`, {
          influencers_ids: socialsFormatted,
        })
        .then(response => {
          message.success('Update request sent successfully!');
          if (onOk) onOk(e);
        })
        .catch(error => {
          message.error('Error sending force update request!');
        });
    },
    [onOk, socialsFormatted],
  );

  return (
    <Modal
      destroyOnClose
      {...rest}
      cancelText="Cancel"
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      title="Update Influencer"
    >
      <p>
        This action will force the influencer data to be updated, but not
        automatically!
      </p>
      <p>Are you sure you want to continue ?</p>
    </Modal>
  );
};

export default ForceUpdateModal;

import React, { useCallback, useState } from 'react';
import Modal, { ModalProps } from 'antd/lib/modal';
import { useIntl } from 'react-intl';
import { saveAs } from 'file-saver';
import XLSX from 'xlsx';
import { Form, Button, InputNumber } from 'antd';
import formatNumber from 'utils/formatNumber';
import { InfluencerType } from 'types';

interface DownloadModalProps extends ModalProps {
  influencers: InfluencerType[];
}

const DownloadModal: React.FC<DownloadModalProps> = ({
  influencers,
  ...rest
}: DownloadModalProps) => {
  const intl = useIntl();
  const [qtd, setQtd] = useState(0);

  const handleDownload = useCallback(() => {
    if (qtd > influencers.length) setQtd(influencers.length);
    const parsed_data = influencers
      .slice(0, qtd)
      .map(influencer => [
        influencer.name || 'N/A',
        influencer.photo || 'N/A',
        influencer.languages?.join(' / ') || 'N/A',
        influencer.social
          .map(
            item => `${item.social_network}(${formatNumber(item.followers)})`,
          )
          .join('/') || 'N/A',
        `${process.env.REACT_APP_BASE_URL}influencers/${influencer.id}` ||
          'N/A',
      ]);
    const columns = [
      'NAME',
      'PHOTO',
      'LANGUAGES',
      'SOCIAL',
      'LINK TO BUZZMONITOR INFLUENCERS',
    ];
    const sheetArrays = [columns, ...parsed_data];
    const sheetName = `Buzzmonitor Influencers - ${new Date()
      .toLocaleDateString()
      .split('/')
      .join(' ')}`;
    const sheet = XLSX.utils.aoa_to_sheet(sheetArrays);
    const csvSheet = XLSX.utils.sheet_to_csv(sheet);
    const blob = new Blob([csvSheet], { type: 'text/csv' });
    return saveAs(blob, `${sheetName.split(' ').join('_')}.csv`);
  }, [influencers, qtd]);

  return (
    <Modal
      {...rest}
      style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}
      title={intl.formatMessage({ id: 'modal.download.title' })}
      onOk={handleDownload}
      cancelText="Cancelar"
      footer={
        <Button htmlType="submit" type="primary" onClick={handleDownload}>
          {intl.formatMessage({ id: 'modal.download.button' })}
        </Button>
      }
    >
      <strong style={{ textAlign: 'center' }}>
        {intl.formatMessage({ id: 'modal.download.msg' })}
      </strong>
      <Form style={{ marginTop: '30px' }}>
        <Form.Item
          labelCol={{ span: 17 }}
          wrapperCol={{ span: 4 }}
          label={`${intl.formatMessage({ id: 'modal.download.msg-continue' })}`}
          colon={false}
        >
          <InputNumber
            placeholder={`Max.: ${influencers?.length}`}
            max={influencers?.length}
            min={0}
            value={qtd === 0 ? 0 : qtd}
            onChange={e => setQtd(e as number)}
            type="number"
            onPressEnter={handleDownload}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DownloadModal;

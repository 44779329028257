import { FavoriteType } from 'types';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import formatNumber from './formatNumber';

export default function (influencers: FavoriteType[]): void {
  const parsed_data = influencers.map(influencer => [
    influencer.name || 'N/A',
    influencer.photo || 'N/A',
    influencer.languages?.join(' / ') || 'N/A',
    influencer.social
      .map(item => `${item.social_network}(${formatNumber(item.followers)})`)
      .join('/') || 'N/A',
    `${process.env.REACT_APP_BASE_URL}influencers/${influencer.id}` || 'N/A',
  ]);
  const columns = [
    'NAME',
    'PHOTO',
    'LANGUAGES',
    'SOCIAL',
    'LINK TO BUZZMONITOR INFLUENCERS',
  ];
  const sheetArrays = [columns, ...parsed_data];
  const sheetName = `Buzzmonitor Influencers - ${new Date()
    .toLocaleDateString()
    .split('/')
    .join(' ')}`;
  const sheet = XLSX.utils.aoa_to_sheet(sheetArrays);
  const csvSheet = XLSX.utils.sheet_to_csv(sheet);
  const blob = new Blob([csvSheet], { type: 'text/csv' });
  return saveAs(blob, `${sheetName.split(' ').join('_')}.csv`);
}

import React, { createContext, useState, useContext, useCallback } from 'react';
import { parseISO, isPast, addDays } from 'date-fns';
import userApi from 'services/userApi';
import Cookies from 'js-cookie';

type Language = 'pt-BR' | 'pt-PT' | 'en' | 'es';

interface User {
  _id: string;
  consumer_type: string;
  language: Language;
  time_zone: string;
  email: string;
  username: string;
  remote_name: string;
  public_api_key: string;
  name: string;
  brands: Array<{ name: string; remote_name: string }>;
}

interface AuthState {
  token: string;
  user: User;
}

interface AuthContextData {
  user: User;
  token: string;
  authorizeUser(): Promise<void>;
}

const USER_INITIAL_STATE = {
  consumer_type:
    process.env.REACT_APP_ENV === 'production'
      ? 'unassigned_costumer'
      : 'employee',
} as User;

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const user = localStorage.getItem(
      `@Buzzmonitor:${process.env.REACT_APP_APPLICATION_NAME}:user`,
    );
    const tokenData = localStorage.getItem(
      `@Buzzmonitor:${process.env.REACT_APP_APPLICATION_NAME}:token`,
    );
    if (tokenData && user) {
      const { token, expire_date } = JSON.parse(tokenData);
      if (!isPast(parseISO(expire_date))) {
        return { token, user: JSON.parse(user) };
      }

      localStorage.removeItem(
        `@Buzzmonitor:${process.env.REACT_APP_APPLICATION_NAME}:user`,
      );
      localStorage.removeItem(
        `@Buzzmonitor:${process.env.REACT_APP_APPLICATION_NAME}:token`,
      );
    }
    return { user: { ...USER_INITIAL_STATE }, token: '' } as AuthState;
  });

  const authorizeUser = useCallback(async () => {
    const token = Cookies.get('token');
    if (token) {
      await userApi
        .get<User[]>('', {
          headers: {
            'access-token': process.env.REACT_APP_USER_API_ACCESS_TOKEN,
            token,
          },
        })
        .then(res => {
          localStorage.setItem(
            `@Buzzmonitor:${process.env.REACT_APP_APPLICATION_NAME}:user`,
            JSON.stringify(res.data[0]),
          );
          localStorage.setItem(
            `@Buzzmonitor:${process.env.REACT_APP_APPLICATION_NAME}:token`,
            JSON.stringify({
              token,
              expire_date: addDays(new Date(), 1).toISOString(),
            }),
          );
          setData({ token, user: res.data[0] });
        })
        .catch(() => {
          setData({ token: '', user: {} as User });
          localStorage.setItem(
            `@Buzzmonitor:${process.env.REACT_APP_APPLICATION_NAME}:user`,
            JSON.stringify({}),
          );
          localStorage.setItem(
            `@Buzzmonitor:${process.env.REACT_APP_APPLICATION_NAME}:token`,
            JSON.stringify({}),
          );
        });
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ user: data.user, token: data.token, authorizeUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider!');
  }

  return context;
}

export { AuthProvider, useAuth };

/* eslint-disable no-underscore-dangle */
import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import api from 'services/crawlerApi';
import { FavoriteType } from 'types';
import { useAuth } from './auth';

interface ContextProps {
  loading: boolean;
  favorites: FavoriteType[];
  loadFavorites: () => void;
  isFavorite: (influencerId: string) => boolean;
  addFavorite: (influencerId: string) => Promise<FavoriteType>;
  deleteFavorite: (influencerId: string) => Promise<FavoriteType>;
  searchFavoritesByName: (name: string) => FavoriteType[];
}

const FavoritesContext = createContext<ContextProps>({} as ContextProps);

const FavoritesProvider: React.FC = ({ children }) => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [favorites, setFavorites] = useState([] as FavoriteType[]);
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${token}`,
    }),
    [token],
  );

  const loadFavorites = useCallback(() => {
    setLoading(true);
    api
      .get('/favorites', { headers })
      .then(res => {
        setFavorites(res.data);
      })
      .catch(() => {
        setLoading(false);
        setFavorites([]);
      })
      .finally(() => setLoading(false));
  }, [headers]);

  useEffect(() => {
    loadFavorites();
  }, [loadFavorites]);

  const isFavorite = useCallback(
    (influencerId: string): boolean =>
      favorites.some(influencer => influencer.id === influencerId),
    [favorites],
  );

  const addFavorite = useCallback(
    (influencerId: string): Promise<FavoriteType> =>
      new Promise((resolve, reject) => {
        api
          .post(
            '/favorites',
            { influencer_id: influencerId },
            {
              headers,
            },
          )
          .then(res => {
            setFavorites(prevFavorites => [...prevFavorites, res.data]);
            resolve(res.data);
          })
          .catch(() => reject());
      }),
    [headers],
  );

  const deleteFavorite = useCallback(
    (influencerId: string): Promise<FavoriteType> =>
      new Promise((resolve, reject) => {
        api
          .delete(`/favorites`, {
            data: { influencer_id: influencerId },
            headers,
          })
          .then(res => {
            setFavorites(prevFavorites =>
              prevFavorites.filter(item => item.id !== influencerId),
            );
            resolve(res.data);
          })
          .catch(() => reject());
      }),
    [headers],
  );

  const searchFavoritesByName = useCallback(
    (name: string): FavoriteType[] =>
      favorites.filter(
        favorite =>
          favorite.name.toLowerCase().indexOf(name.toLowerCase()) > -1,
      ),
    [favorites],
  );

  return (
    <FavoritesContext.Provider
      value={{
        loading,
        loadFavorites,
        favorites,
        isFavorite,
        addFavorite,
        deleteFavorite,
        searchFavoritesByName,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};

export function useFavorites(): ContextProps {
  const context = useContext(FavoritesContext);
  if (!context) {
    throw new Error('useFavorites must be used within an FavoritesProvider!');
  }
  return context;
}

export default FavoritesProvider;

import { useIntl } from 'react-intl';
import React, { useState, useCallback } from 'react';

import Modal, { ModalProps } from 'antd/lib/modal';
import { message, Form, Input } from 'antd';
import api from 'services/crawlerApi';
import { useAuth } from 'hooks/auth';
import { useCurrentInfluencer } from 'hooks/currentInfluencer';

const { TextArea } = Input;

const ReportModal: React.FC<ModalProps> = ({
  visible,
  onCancel,
  onOk,
  ...rest
}) => {
  const intl = useIntl();
  const { token } = useAuth();
  const [form] = Form.useForm();
  const { currentInfluencer } = useCurrentInfluencer();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onSubmitSuccess = useCallback(
    (e): void => {
      message.success(
        intl.formatMessage({
          id: 'profile.header.modal.report.success',
        }),
        2,
      );
      if (onOk) onOk(e);
    },
    [intl, onOk],
  );

  const onSubmitFail = useCallback(() => {
    message.error(
      intl.formatMessage({
        id: 'profile.header.modal.report.error',
      }),
      2,
    );
  }, [intl]);

  const handleCancel = useCallback(
    e => {
      form.setFieldsValue({ report_description: '' });
      if (onCancel) onCancel(e);
    },
    [form, onCancel],
  );

  const handleSubmit = useCallback(
    e => {
      setLoadingSubmit(true);
      form
        .validateFields()
        .then(values => {
          api
            .post(
              '/influencers/reported/profile',
              { ...values, influencer_id: currentInfluencer.id },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
            .then(() => {
              onSubmitSuccess(e);
            })
            .catch(() => {
              onSubmitFail();
            })
            .finally(() => {
              form.resetFields()
              setLoadingSubmit(false);
            });
        })
        .catch(() => {
          setLoadingSubmit(false);
        });
    },
    [currentInfluencer.id, form, onSubmitFail, onSubmitSuccess, token],
  );

  return (
    <Modal
      destroyOnClose
      {...rest}
      cancelText={intl.formatMessage({
        id: 'profile.header.modal.report.cancel',
      })}
      okText={intl.formatMessage({
        id: 'profile.header.modal.report.submit',
      })}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleSubmit}
      title={intl.formatMessage({
        id: 'profile.header.modal.report.title',
      })}
      confirmLoading={loadingSubmit}
    >
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Form.Item
          name="report_description"
          label={intl.formatMessage({
            id: 'profile.header.modal.report.description',
          })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'profile.header.modal.report.description.error',
              }),
            },
          ]}
        >
          <TextArea
            rows={4}
            placeholder={intl.formatMessage({
              id: 'profile.header.modal.report.description.placeholder',
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReportModal;

import React, { useState, useCallback, useEffect, useMemo } from 'react';

import Modal, { ModalProps } from 'antd/lib/modal';
import api from 'services/crawlerApi';

import { Checkbox, message } from 'antd';
import { SocialType, InfluencerType } from 'types';
import { useParams } from 'react-router-dom';
import { useCurrentInfluencer } from 'hooks/currentInfluencer';

import InfluencersList from 'components/InfluencersList';
import { ProfilesSelected } from './styles';

const SplitModal: React.FC<ModalProps> = ({
  visible,
  onCancel,
  onOk,
  ...rest
}) => {
  const { id } = useParams();
  const { currentInfluencer } = useCurrentInfluencer();
  const [profiles, setProfiles] = useState([] as SocialType[]);
  const [profilesToSplit, setProfilesToSplit] = useState(
    [] as InfluencerType[],
  );
  const { confirm } = Modal;

  useEffect(() => {
    setProfiles(currentInfluencer.social);
  }, [currentInfluencer.social]);

  const handleCheckboxChange = useCallback(checkedList => {
    setProfilesToSplit(checkedList);
  }, []);

  const handleSubmit = (): void => {
    confirm({
      title: 'This action will split the influencers selected!',
      content: (
        <>
          <p>Profiles selected:</p>
          <ProfilesSelected>
            <InfluencersList list={profilesToSplit} />
          </ProfilesSelected>
          <span>Are you sure you want to continue ?</span>
        </>
      ),
      onOk: e => {
        const socialIds = profilesToSplit.map(item => item.id);
        api
          .post(`/influencers/split/${id}`, { social_ids: socialIds })
          .then(response => {
            message.success('Profiles splitted with success!');
            Modal.destroyAll();
            if (onOk) onOk(e);
          })
          .catch(error => {
            message.error('Error spliting the profiles, try again!');
            Modal.destroyAll();
          });
      },
    });
  };

  const handleCancel = useCallback(
    e => {
      setProfiles(currentInfluencer.social);
      setProfilesToSplit([] as InfluencerType[]);
      Modal.destroyAll();
      if (onCancel) onCancel(e);
    },
    [currentInfluencer.social, onCancel],
  );

  return (
    <Modal
      destroyOnClose
      {...rest}
      cancelText="Cancel"
      visible={visible}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okButtonProps={{ disabled: profilesToSplit.length < 1 }}
      title="Split Influencer"
    >
      {profiles.length > 0 && (
        <Checkbox.Group
          style={{ width: '100%' }}
          onChange={handleCheckboxChange}
        >
          <SocialsList
            list={profiles}
            defaultSocialUsername={currentInfluencer.name}
            checkable
          />
        </Checkbox.Group>
      )}
    </Modal>
  );
};

interface SocialsListProps {
  list: SocialType[];
  checkable: boolean;
  defaultSocialUsername: string;
}

const SocialsList: React.FC<SocialsListProps> = ({
  list,
  checkable,
  defaultSocialUsername,
}) => {
  const formattedList = useMemo((): InfluencerType[] => {
    return list.map(item => ({
      id: item.id,
      photo: item.avatar,
      name:
        item.social_network === 'facebook'
          ? defaultSocialUsername
          : item.username,
      social: [
        {
          id: `social${item.id}`,
          social_network: item.social_network,
          followers: item.followers,
        },
      ],
    })) as InfluencerType[];
  }, [defaultSocialUsername, list]);

  return <InfluencersList list={formattedList} checkable={checkable} />;
};

export default SplitModal;

import React, { useMemo, useCallback, memo } from 'react';
import { useHistory } from 'react-router-dom';

import { uuid } from 'uuidv4';
import { UserOutlined } from '@ant-design/icons';
import { useInfluencers } from 'hooks/influencers';
import { InfluencerType, SocialType } from 'types';
import formatNumber from 'utils/formatNumber';

import { Tag, Popover } from 'antd';
import { useIntl } from 'react-intl';
import { uniqBy } from 'lodash';

import InfluencerFavoriteButton from 'components/InfluencerFavoriteButton';
import SocialIcon from 'components/SocialIcon';

import {
  Container,
  CardPicture,
  Name,
  Social,
  SocialFollowers,
  CardIndex,
  CardHeader,
  CardContent,
  TagDivider,
  TagList,
  FavoriteButtonContainer,
} from './styles';

interface InfluencerCardProps {
  data: InfluencerType;
  index?: number;
  loading?: boolean;
  enabled?: boolean;
  key: string;
  openModal?: (modal: boolean) => void;
}

const InfluencerCard: React.FC<InfluencerCardProps> = ({
  data = { id: '', name: '', photo: '', social: [] },
  index = 0,
  loading = false,
  enabled = true,
  openModal = () => undefined,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { filters } = useInfluencers();
  const { id, social, name, photo, explain } = data;

  const foundByTags = useMemo(() => {
    let tags: string[] = [];
    if (explain) {
      tags = uniqBy(explain, 'query').map(tag => tag.query);
    }
    return [
      tags.slice(0, 3),
      tags.length > 3 ? tags.slice(3, tags.length) : [],
    ];
  }, [explain]);

  function handleSelect(): void {
    if (loading) return;
    if (enabled) {
      history.push(`/influencers/${id}`);
      window.scrollTo(0, 0);
    } else {
      openModal(true);
    }
  }

  const FavoriteButton = useCallback(
    () => (
      <FavoriteButtonContainer>
        <InfluencerFavoriteButton influencerId={`${id}`} />
      </FavoriteButtonContainer>
    ),
    [id],
  );

  return (
    <Container
      onClick={handleSelect}
      enabled={enabled}
      title={
        <CardHeader>
          <CardIndex>{index}</CardIndex>
          <CardPicture src={photo} icon={<UserOutlined />} />
        </CardHeader>
      }
    >
      <CardContent>
        <FavoriteButton />
        <Name>{name}</Name>
        <SocialPreview socials={social} />
        {filters.searchFor !== '' && filters.whereToSearch.length > 1 && (
          <TagList>
            <TagDivider>
              {intl.formatMessage({ id: 'home.influencer-card.found-by' })}
            </TagDivider>
            <div>
              {foundByTags[0].map(tag => (
                <Tag color="#108ee9">
                  {intl.formatMessage({
                    id: `home.influencer-card.found-by.${String(tag).replace(
                      '_',
                      '-',
                    )}`,
                  })}
                </Tag>
              ))}
              {foundByTags[1].length > 0 && (
                <Popover
                  content={foundByTags[1]
                    .map(tag =>
                      intl.formatMessage({
                        id: `home.influencer-card.found-by.${String(
                          tag,
                        ).replace('_', '-')}`,
                      }),
                    )
                    .join(', ')}
                >
                  <strong>+ {foundByTags[1].length}</strong>
                </Popover>
              )}
            </div>
          </TagList>
        )}
      </CardContent>
    </Container>
  );
};

interface SocialPreviewProps {
  socials: SocialType[];
}

const SocialPreview: React.FC<SocialPreviewProps> = ({ socials }) => {
  const socialsFormatted = useMemo(
    () => [
      socials.slice(0, 4),
      socials.length > 4 ? socials.slice(4, socials.length) : [],
    ],
    [socials],
  );

  return (
    <Social>
      {socialsFormatted[0].map(social => {
        const { social_network, followers } = social;
        const id = uuid();
        const formattedCount = formatNumber(followers);
        return (
          <SocialFollowers key={id}>
            <SocialIcon social_network={social_network} />
            <span>{formattedCount}</span>
          </SocialFollowers>
        );
      })}
      {socialsFormatted[1].length > 0 && (
        <Popover content={`+ ${socialsFormatted[1].length} perfis sociais`}>
          <strong>+ {socialsFormatted[1].length}</strong>
        </Popover>
      )}
    </Social>
  );
};

const comparisonFn = (
  prevProps: InfluencerCardProps,
  newProps: InfluencerCardProps,
): boolean => {
  return JSON.stringify(prevProps.data) === JSON.stringify(newProps.data);
};

export default memo(InfluencerCard, comparisonFn);

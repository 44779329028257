import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 24px;
  padding: 24px;
  margin-top: 0px;
  @media (max-width: 1080px) {
    padding: 0px;
    grid-gap: 16px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PopoverContainer = styled.div`
  width: 300px;
`;
